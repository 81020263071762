import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Chart } from 'react-google-charts';

import Loader from '../../components/Loader';
import Breadcrumbs from '../../components/Breadcrumbs/breadcrumbs';
import { get_fcr_session, get_fcr_session_log } from '../../api';

const ControlSessionDetails = () => {
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState({});
    const [sessionName, setSessionName] = useState('Control Session');
    let { id, sessionId } = useParams();

    useEffect(() => {
        (async () => {
            const session = await get_fcr_session(sessionId);
            setSession(session.data);
            setLoading(false);
            const start = new Date(session.data.start);
            setSessionName(`${session.data.market} - ${start.toLocaleDateString()} - ${start.toLocaleTimeString()}`);
        })();
    }, [id, sessionId]);

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Breadcrumbs
                crumbs={[
                    { name: 'Virtual Batteries', to: '/batteries' },
                    { name: session?.battery_name || 'Unnamed Battery', to: `/batteries/${id}` },
                    { name: sessionName, to: `/batteries/${id}/sessions/${sessionId}` },
                ]}
            />

            <div className="battery-details__two-card-row">
                <div className="card-wrapper">
                    <h4>{sessionName}</h4>
                    <ControlSessionDetailsTable session={session} />
                </div>

                <div className="card-wrapper">
                    <ControlSessionDevicesList session={session} />
                </div>
            </div>

            <div className="card-wrapper">
                <ControlSessionLogPlot sessionName={sessionName} />
            </div>
        </>
    );
};

const ControlSessionDetailsTable = ({ session }) => {
    const start = new Date(session.start);
    const end = new Date(session.end);

    return (
        <table className="battery-details__table">
            <colgroup>
                <col width="50%" />
                <col width="50%" />
            </colgroup>
            <thead>
                <tr>
                    <th>Property</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Date</td>
                    <td>{start.toLocaleDateString()}</td>
                </tr>
                <tr>
                    <td>Start time</td>
                    <td>{start.toLocaleTimeString()}</td>
                </tr>
                <tr>
                    <td>End time</td>
                    <td>{end.toLocaleTimeString()}</td>
                </tr>
                <tr>
                    <td>Battery name</td>
                    <td>{session.battery_name}</td>
                </tr>
                <tr>
                    <td>Market</td>
                    <td>{session.market}</td>
                </tr>
                <tr>
                    <td>Price Area</td>
                    <td>{session.price_area}</td>
                </tr>
                <tr>
                    <td>Test type (if test session)</td>
                    <td>{session.test_type}</td>
                </tr>
            </tbody>
        </table>
    );
};

const ControlSessionLogPlot = ({ sessionName }) => {
    const [loading, setLoading] = useState(true);
    const [sessionLog, setSessionLog] = useState([]);
    let { sessionId } = useParams();
    const ref = React.useRef();

    useEffect(() => {
        (async () => {
            const sessionLog = await get_fcr_session_log(sessionId);
            setSessionLog(sessionLog.data);
            updateDownloadLink(sessionLog.data);
            setLoading(false);
        })();
    }, [sessionId]);

    function updateDownloadLink(data) {
        const jsonData = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data, null, 2));
        const downloadAnchorNode = ref.current;
        downloadAnchorNode.setAttribute('href', jsonData);
        const filename = `${sessionName}.json`;
        downloadAnchorNode.setAttribute('download', filename);
    }

    const power_data = [['Time', 'Power Consumption (MW)', 'Power Set Point (MW)']];
    const frequency_data = [['Time', 'Grid frequency (Hz)']];
    for (let point of sessionLog) {
        power_data.push([new Date(point.time), point.power_consumption, point.power_set_point]);
        frequency_data.push([new Date(point.time), point.grid_frequency]);
    }

    const options_power = {
        legend: { position: 'top' },
        chartArea: {
            height: '80%',
            width: '100%',
        },
        vAxis: {
            title: 'Power (MW)',
            textPosition: 'in',
        },
    };

    const options_freq = {
        legend: { position: 'top' },
        chartArea: {
            height: '80%',
            width: '100%',
        },
        vAxis: {
            textPosition: 'in',
            title: 'Frequency (Hz)',
        },
    };

    return (
        <div>
            <div className="battery-details__edit">
                <h2>Session Logs</h2>
                <a ref={ref} id="download-log-link">
                    Download log data
                </a>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <div className="charge-sessions__plots reporting__graph-wrapper">
                    {power_data.length !== 1 ? (
                        <div className="charge-sessions__plots--chart">
                            <h3 className="reporting__title">Power and set point</h3>
                            <Chart chartType="LineChart" width="100%" height="300px" data={power_data} options={options_power} />
                        </div>
                    ) : (
                        <div className="batteries__no-batteries">No power data found!</div>
                    )}
                    {frequency_data.length !== 1 ? (
                        <div className="charge-sessions__plots--chart">
                            <h3 className="reporting__title">Grid frequency</h3>
                            <Chart chartType="LineChart" width="100%" height="300px" data={frequency_data} options={options_freq} />
                        </div>
                    ) : (
                        <div className="batteries__no-batteries">No frequency data found!</div>
                    )}
                </div>
            )}
        </div>
    );
};

const ControlSessionDevicesList = ({ session }) => {
    const { session_devices } = session;

    return (
        <div>
            <h3>Devices used</h3>
            <div className="batteries__table-wrapper">
                {session_devices.length === 0 ? (
                    <div className="batteries__no-batteries">No devices found!</div>
                ) : (
                    <table className="batteries__table">
                        <thead className="batteries__table--header">
                            <tr>
                                <th>
                                    <span className="batteries__table-header-sort">Id</span>
                                </th>
                                <th>
                                    <span className="batteries__table-header-sort">Device type</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {session_devices.map(device => (
                                <tr key={device.id} className="batteries-table-row">
                                    <td>
                                        <p>{device.id}</p>
                                    </td>
                                    <td>{device.type || 'battery'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default ControlSessionDetails;
