import { createStore } from 'redux';

const INITIAL_STATE = {
    controlmainPower: true,
    schedulemainPower: true,
    manualMode: false,
    sliderDisabled: true,
};

const mainPowerReducer = (state = INITIAL_STATE, action) => {
    if (action.type === 'controlMainPower') {
        return state.manualMode === true && state.schedulemainPower === false && state.controlmainPower === false
            ? {
                  controlmainPower: true,
                  schedulemainPower: true,
                  manualMode: false,
                  sliderDisabled: true,
              }
            : state.manualMode === false && state.schedulemainPower === true && state.controlmainPower === true
            ? {
                  controlmainPower: false,
                  schedulemainPower: false,
                  manualMode: true,
                  sliderDisabled: false,
              }
            : state.manualMode === true && state.schedulemainPower === false && state.controlmainPower === true
            ? {
                  controlmainPower: false,
                  schedulemainPower: false,
                  manualMode: true,
                  sliderDisabled: true,
              }
            : state.manualMode === false && state.schedulemainPower === true && state.controlmainPower === true
            ? {
                  controlmainPower: false,
                  schedulemainPower: false,
                  manualMode: true,
                  sliderDisabled: true,
              }
            : state.manualMode === true && state.schedulemainPower === false && state.controlmainPower === true
            ? {
                  controlmainPower: false,
                  schedulemainPower: false,
                  manualMode: true,
                  sliderDisabled: true,
              }
            : state.manualMode === true && state.schedulemainPower === false
            ? {
                  controlmainPower: !state.controlmainPower,
                  schedulemainPower: false,
                  manualMode: false,
                  sliderDisabled: true,
              }
            : {
                  controlmainPower: !state.controlmainPower,
                  schedulemainPower: !state.schedulemainPower,
                  manualMode: !state.manualMode,
                  sliderDisabled: !state.sliderDisabled,
              };
    }

    if (action.type === 'scheduleMainPower') {
        return state.controlmainPower === true && state.manualMode === true && state.schedulemainPower === false && state.sliderDisabled === false
            ? {
                  ...state,
                  manualMode: false,
                  schedulemainPower: true,
                  sliderDisabled: true,
              }
            : state.controlmainPower === false && state.manualMode === false && state.schedulemainPower === false && state.sliderDisabled === true
            ? {
                  ...state,
              }
            : {
                  ...state,
                  schedulemainPower: !state.schedulemainPower,
                  manualMode: !state.manualMode,
                  sliderDisabled: !state.sliderDisabled,
              };
    }

    if (action.type === 'manualMode') {
        return state.controlmainPower === true && state.schedulemainPower === false && state.manualMode === true
            ? {
                  ...state,
                  schedulemainPower: true,
                  manualMode: false,
                  sliderDisabled: true,
              }
            : state.controlmainPower === true && state.schedulemainPower === true && state.manualMode === false
            ? {
                  ...state,
                  schedulemainPower: false,
                  manualMode: true,
                  sliderDisabled: false,
              }
            : {
                  ...state,
                  schedulemainPower: !state.schedulemainPower,
                  manualMode: !state.manualMode,
                  sliderDisabled: !state.sliderDisabled,
              };
    }

    return state;
};

const store = createStore(mainPowerReducer);

export default store;
