import { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { retailerReportingQuery } from '../../../../api';

export const SavingsHistogram = ({ granularity, from, to }) => {
    const [data, setData] = useState([]);
    const [hasNonZeroData, setHasNonZeroData] = useState(false);

    const getQueryResults = async () => {
        // this should not be used publicly outside of this module - this is
        // a temporary solution for setting the default from/to dates
        // for this chart type. in the future we would like to move this into
        // it's own retailer reporting tab with explicit from/to values.
        const defaultFrom = (granularity, to) => {
            let result = to ? new Date(to) : new Date();

            switch (granularity) {
                case 'DAY':
                    result.setDate(result.getDate() - 29);
                    break;
                case 'WEEK':
                    result.setDate(result.getDate() - 51 * 7);
                    break;
                case 'MONTH':
                    result.setMonth(result.getMonth() - 11);
                    break;
                default:
                    break;
            }

            return result;
        };

        let fromValue = from === null ? defaultFrom(granularity, to) : from;
        let toValue = to === null ? new Date() : to;
        let queryResults;

        queryResults = await retailerReportingQuery(
            `query DeviceEventCount($from: Date!, $to: Date!) {
                 DEVICE_PERIOD_SAVINGS: devicePeriodSavings(from: $from, to: $to) {
                     ...deviceSavingsBreakdown
                 },
             }

             fragment deviceSavingsBreakdown on DevicePeriodSavings {
                 device_id,
                 savings {
                     savings_percentage,
                 }
             }`,
            { from: fromValue, to: toValue }
        );

        let chartArray = [['Device ID', 'Relative Savings (%)']];
        for (let record of queryResults['DEVICE_PERIOD_SAVINGS']) {
            if (record.savings.savings_percentage > 0) {
                setHasNonZeroData(true);
                chartArray.push([`Device ID: ${record.device_id}`, (record.savings.savings_percentage * 100).toFixed(1)]);
            }
        }

        setData(chartArray);
    };

    useEffect(() => {
        getQueryResults();
    }, [granularity, from, to]);

    const options = {
        hAxis: {
            slantedText: false,
            textStyle: {
                fontSize: 14,
            },
            title: 'Relative Savings (%)',
        },
        legend: { position: 'none' },
        vAxis: {
            format: '0',
            ...(hasNonZeroData ? {} : { viewWindow: { min: 0, max: 1 } }),
        },
        chartArea: {
            height: '80%',
        },
        interpolateNulls: false,
    };

    return (
        <>
            <div className="reporting__graph-wrapper--chart">
                <h3 className="reporting__title">Savings Histogram</h3>
                {data.length > 1 ? (
                    <Chart chartType="Histogram" width="100%" height="400px" data={data} options={options} />
                ) : (
                    <p className="reporting__no-data-message">No data for this time period.</p>
                )}
            </div>
        </>
    );
};
