/* global google */
import React, { useEffect, useState } from 'react';
import useGetAllFacilities from '../../hooks/useGetAllFacilities';
import useGetActiveCustomers from '../../hooks/useGetActiveCustomers';
import Loader from '../../components/Loader';
import { CSVLink } from 'react-csv';
import Chart from 'react-google-charts';

import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { useGraphQLGetActiveDevices } from '../../hooks/useGraphQLGetActiveDevices';
import { DateTime } from 'luxon';
import cc from 'classcat';
import { deviceTypeMap } from '../../utils/constants';

const mapStyle = [
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36,
            },
            {
                color: '#000000',
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#000000',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#000000',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#000000',
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#F26E48',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#fefefe',
            },
        ],
    },
    {
        featureType: 'administrative.province',
        elementType: 'geometry',
        stylers: [
            {
                color: '#866139',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#000000',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#474e54',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#000000',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#000000',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#000000',
            },
            {
                lightness: 18,
            },
            {
                weight: 0.2,
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#000000',
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#000000',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#000000',
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#9eb0b0',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
];

const Overview = () => {
    const colorMap = {
        cs: '#E54F20',
        ev: '#161616',
        hvac: '#A9C4C4',
        si: '#FFB536',
        sm: '#F57E02',
        bat: '#B0B8B7',
        hub: '#5AC7CF',
        other: '#E3B8A5',
    };

    const { isReady: facilitiesReady, data: facilities } = useGetAllFacilities();
    const { isReady: customersReady, data: customers } = useGetActiveCustomers();

    const oneMonthAgo = DateTime.now().minus({ months: 1 }).toJSDate();
    const { isReady: statisticsReady, data: statistics, distinctTypes } = useGraphQLGetActiveDevices('DAY', oneMonthAgo, new Date());

    const [deviceNumberGraph, setDeviceNumberGraph] = useState();
    const [devicesPerDateGraph, setDevicesPerDateGraph] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState();
    const [mapRef, setMapRef] = useState();
    const [colorsPerDate, setColorsPerDate] = useState();
    const [pieChartColor, setPieChartColor] = useState();

    const latestStats = statistics[statistics.length - 1];

    const mapMarkers = [];
    const mapBounds = new google.maps.LatLngBounds();
    facilities.forEach(facility => {
        const hasDevices = customers.includes(facility.retailer_customer_id);
        if (facility.location && hasDevices) {
            mapMarkers.push({
                lat: facility.location.lat,
                lng: facility.location.lng,
                customerId: facility.retailer_customer_id,
                key: facility.facility_id,
            });
            mapBounds.extend({ lat: facility.location.lat, lng: facility.location.lng, customerId: facility.retailer_customer_id });
        }
    });

    const generateDate = dateString => {
        return DateTime.fromISO(dateString).setZone('utc').setZone('system', { keepLocalTime: true }).toJSDate();
    };

    useEffect(() => {
        if (statistics && statistics.length > 0 && distinctTypes.length > 0) {
            const graphArray = [['Element', 'No. of device']];
            const devicesPerDate = [['Date', ...distinctTypes]];

            latestStats.allTypes.forEach(type => {
                const deviceType = deviceTypeMap[type.toLowerCase()];
                graphArray.push([deviceType || 'Unknown type', latestStats[type] || 0]);
            });
            setPieChartColor(latestStats.allTypes.map(type => colorMap[type.toLowerCase()] || '#000000'));

            const colors = distinctTypes.map(label => colorMap[label.toLowerCase()] || '#000000');
            setColorsPerDate(colors);

            statistics.forEach(day => {
                const temp = [generateDate(day.start)];
                distinctTypes.forEach(type => {
                    temp.push(day[type] || 0);
                });

                devicesPerDate.push(temp);
            });

            setDeviceNumberGraph(graphArray);
            setDevicesPerDateGraph(devicesPerDate);
        }
    }, [statistics, distinctTypes]);

    useEffect(() => {
        if (mapBounds.isEmpty()) {
            mapRef?.setCenter({ lat: 60, lng: 18 });
        } else {
            mapRef?.fitBounds(mapBounds);
        }
    }, [facilities, customers, mapRef]);

    const onLoad = map => {
        setMapRef(map);
    };

    const handleMarkerClick = id => {
        window.open(`/devicesdetail/${id}`, '_self');
    };

    const handleHover = customerId => {
        setInfoWindowData({ id: customerId });
        setIsOpen(true);
    };

    return (
        <div className="overview">
            {!facilitiesReady && !customersReady && !statisticsReady ? (
                <Loader />
            ) : (
                <div className="overview__wrapper">
                    <div className="overview__charts-wrapper">
                        <div className="card-wrapper overview__number">
                            <div className="card__header">Devices</div>
                            <div id="chart-body" style={{ height: '245px' }}>
                                <div className={cc(['overview__stats', latestStats?.count === 0 && 'overview__stats--no-margin'])}>
                                    <table className="overview__stats--table">
                                        <thead>
                                            <tr>
                                                <th className="overview__stats--customers">{customers.length}</th>
                                                {latestStats?.allTypes.map(type => (
                                                    <th key={type}>{latestStats[type] || 0}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="overview__stats--customers">Customers</td>
                                                {latestStats?.allTypes.map(type => (
                                                    <td key={type}>{deviceTypeMap[type.toLowerCase()] || 'Unknown Type'}</td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {deviceNumberGraph && (
                                    <div>
                                        {latestStats.count !== 0 && (
                                            <Chart
                                                loader={<Loader className="overview__chart-loader" />}
                                                chartType="PieChart"
                                                width="380px"
                                                height="180px"
                                                data={deviceNumberGraph}
                                                options={{
                                                    width: '100%',
                                                    pieHole: 0.6,
                                                    bar: { groupWidth: '40%' },
                                                    legend: {
                                                        position: 'right',
                                                        maxLines: 2,
                                                        alignment: 'center',
                                                        textStyle: {
                                                            fontSize: '10.1',
                                                        },
                                                    },
                                                    backgroundColor: { fill: 'none' },
                                                    pieSliceText: 'none',
                                                    chartArea: { top: 45 },
                                                    colors: pieChartColor,
                                                }}
                                            />
                                        )}
                                        <p className="overview__total-devices">
                                            Total devices: <span>{latestStats?.count}</span>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="card-wrapper overview__over-time">
                            <div className="overview__over-time-header card__header">
                                Devices over time
                                {devicesPerDateGraph && (
                                    <button className="devices__over-time-header--download-btn">
                                        <CSVLink
                                            {...{
                                                data: devicesPerDateGraph,
                                                filename: 'devices_per_date.csv',
                                            }}
                                        >
                                            <i className="fa fa-download"></i>
                                        </CSVLink>
                                    </button>
                                )}
                            </div>
                            {devicesPerDateGraph && (
                                <div
                                    id="line_chart_body"
                                    className="card-body"
                                    style={{
                                        height: '410px',
                                    }}
                                >
                                    <Chart
                                        loader={<Loader className="overview__chart-loader" />}
                                        chartType="ColumnChart"
                                        width="100%"
                                        height="430px"
                                        data={devicesPerDateGraph}
                                        options={{
                                            isStacked: true,
                                            width: '100%',
                                            legend: { position: 'none' },
                                            backgroundColor: { fill: 'none' },
                                            chartArea: { top: 40 },
                                            hAxis: {
                                                format: 'MMM \u0060yy',
                                                slantedText: false,
                                                textStyle: {
                                                    fontSize: 14,
                                                },
                                            },
                                            vAxis: { format: '0' },
                                            colors: colorsPerDate,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="card-wrapper overview__map">
                        <div className="card__header overview__map-header">Map</div>
                        <div className="card-body">
                            <GoogleMap
                                onLoad={onLoad}
                                mapContainerClassName="map-container"
                                mapContainerStyle={{ width: '100%', minHeight: '800px' }}
                                options={{
                                    styles: mapStyle,
                                }}
                                onClick={() => setIsOpen(false)}
                                zoom={4}
                            >
                                {mapMarkers.map(x => (
                                    <Marker
                                        key={x.key}
                                        position={{ lat: x.lat, lng: x.lng }}
                                        icon="/assets/icons/map-marker.svg"
                                        onClick={() => {
                                            handleMarkerClick(x.customerId);
                                        }}
                                        onMouseOver={() => {
                                            handleHover(x.customerId);
                                        }}
                                        onMouseOut={() => setIsOpen(false)}
                                        animation={google.maps.Animation.DROP}
                                    >
                                        {isOpen && infoWindowData?.id === x.customerId && (
                                            <InfoWindow
                                                onCloseClick={() => {
                                                    setIsOpen(false);
                                                }}
                                                options={{
                                                    maxWidth: 200,
                                                }}
                                            >
                                                <div id="content">
                                                    <div id="bodyContent" style={{ margin: '3px 0' }}>
                                                        <p style={{ marginLeft: '8px', fontSize: '14px' }}>
                                                            Customer: <b>{x.customerId}</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </InfoWindow>
                                        )}
                                    </Marker>
                                ))}
                            </GoogleMap>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Overview;
