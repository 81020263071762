import React from 'react';
import Loader from '../../../components/Loader';
import { DeviceEventGraphByManufacturer, DeviceEventGraphByType } from './Graphs';

const DeviceEventSection = ({ metadata, results, granularity, breakdown, dates }) => {
    return (
        <div className="reporting__graph-wrapper--chart">
            <h3 className="reporting__title">
                {metadata.display} {metadata.note && <small>({metadata.note})</small>}
            </h3>
            {results ? (
                breakdown === 'type' ? (
                    <DeviceEventGraphByType results={results} granularity={granularity} dates={dates} />
                ) : (
                    <DeviceEventGraphByManufacturer results={results} granularity={granularity} dates={dates} />
                )
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default DeviceEventSection;
