import React from 'react';
import Popup from 'reactjs-popup';

const NewUserModal = ({ open, handleClose, user }) => {
    return (
        <Popup open={open} onClose={handleClose} modal className="modal">
            <div>
                <button className="modal__close" onClick={handleClose}>
                    &#10005;
                </button>
                <div className="modal__header"> New User Created </div>
                <div className="modal__content">
                    <div className="user-management__user-created">
                        <p>The user has been created. The credentials are:</p>
                        <p>
                            <b>Email:</b> {user?.email}
                        </p>
                        <p>
                            <b>Password:</b> {user?.password}
                        </p>
                        <p>Note the temporary password must be changed.</p>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default NewUserModal;
