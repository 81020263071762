import { useEffect, useState } from 'react';
import api from '../api';

const useGetDevicesForCustomer = (customerId, includeDeleted) => {
    const [isReady, setReady] = useState(false);
    const [data, setData] = useState([]);

    const getDevices = async () => {
        try {
            const devices = await api.get_devices_for_customer(customerId, includeDeleted);
            setData(devices.data);
            setReady(true);
        } catch (error) {
            console.error('Error happened while loading devices. ', error);
        }
    };

    useEffect(() => {
        getDevices();
    }, []);

    return { isReady, data };
};

export default useGetDevicesForCustomer;
