export const deviceTypeMap = {
    cs: 'Charge Station',
    ev: 'Electric Vehicle',
    hvac: 'HVAC',
    si: 'Solar Inverter',
    sm: 'Smart Meter',
    bat: 'Battery',
    hub: 'EDAP Hub',
    other: 'Other',
};
