import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'graphiql/graphiql.css';
import ReactDOMServer from 'react-dom/server';
import { DateTime } from 'luxon';
import Advanced from './Advanced/Advanced';
import Devices from './DeviceReports/Devices';
import Customers from './CustomerReports/Customers';

export const GRANULARITIES = [
    { value: 'DAY', display: 'Day' },
    { value: 'WEEK', display: 'Week' },
    { value: 'MONTH', display: 'Month' },
];

export const BREAKDOWNS = [
    { value: 'type', display: 'Device Type' },
    { value: 'manufacturer', display: 'Device Manufacturer' },
];

function generateDate(dateString) {
    return DateTime.fromISO(dateString).setZone('utc').setZone('system', { keepLocalTime: true }).toJSDate();
}

export function xAxisLabels(data) {
    return data?.map(d => {
        return generateDate(d?.start);
    });
}

function getStartOfWeek(date) {
    const formattedStartDate = DateTime.fromISO(date).startOf('week').toFormat('dd-mm-yyyy');
    return `Week starting on ${formattedStartDate}`;
}

export function getTooltip(granularity, date) {
    let tooltip = '';
    switch (granularity) {
        case 'MONTH':
            tooltip = new Date(date.start).toLocaleDateString('en-GB', { month: 'long', year: 'numeric' });
            break;
        case 'WEEK':
            tooltip = getStartOfWeek(date.start);
            break;
        default:
            tooltip = new Date(date.start).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
    }

    return tooltip;
}

export function deviceTypeBreakdownTooltip(typePermutations) {
    let sortedTypePermutations = sortByCount(typePermutations);
    return ReactDOMServer.renderToString(
        <div>
            {sortedTypePermutations.map(typePermutation => {
                let typeCounts = {};
                for (let type of typePermutation.types.map(t => t.toUpperCase())) {
                    typeCounts[type] ||= 0;
                    typeCounts[type]++;
                }
                let title = Object.keys(typeCounts)
                    .sort()
                    .map(t => `${typeCounts[t]} x ${t}`)
                    .join(', ');
                return (
                    <div key={title}>
                        <div
                            style={{
                                minWidth: '360px',
                                maxWidth: '380px',
                                padding: '5px 10px 5px 10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '10px',
                                maxHeight: '200px',
                                overflow: 'auto',
                            }}
                        >
                            <strong style={{ paddingRight: '10px' }}>{title}</strong>
                            <span style={{ float: 'right' }}>{typePermutation.customer}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export function granularityToUnitAndFormat(granularity) {
    switch (granularity) {
        case 'DAY':
            return ['Day', 'dd MMM'];
        case 'WEEK':
            return ['Week', 'dd MMM'];
        default:
            return ['Month', 'MMM YYYY'];
    }
}

export function sortByCount(dataset) {
    return dataset.sort((a, b) => b.count - a.count);
}

const RetailerReporting = () => {
    return (
        <Tabs className="tabs">
            <TabList className="tabs__tab-nav">
                <Tab>Devices</Tab>
                <Tab>Customers</Tab>
                <Tab>Advanced</Tab>
            </TabList>
            <TabPanel>
                <div className="tabs__tab-content">
                    <Devices />
                </div>
            </TabPanel>
            <TabPanel>
                <div className="tabs__tab-content">
                    <Customers />
                </div>
            </TabPanel>
            <TabPanel>
                <div className="tabs__tab-content">
                    <Advanced />
                </div>
            </TabPanel>
        </Tabs>
    );
};

export default RetailerReporting;
