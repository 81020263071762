import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import api from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Input, Select } from '../../components/Common';

const EditVirtualBatteryModal = ({ battery, open, setOpen, setBattery }) => {
    const systems = [
        { value: '', label: 'Trading System', hidden: true },
        { value: 'trayport', label: 'trayport' },
    ];

    const [name, setName] = useState(battery.name || '');
    const [system, setSystem] = useState(battery.system);
    const [systemSettings, setSystemSettings] = useState(JSON.stringify(battery.system_settings || {}));
    const [forecastHorizon, setForecastHorizon] = useState(24 * 2 * 4);
    const [deviceLabels, setDeviceLabels] = useState(JSON.stringify(battery.device_labels || {}));
    const [error, setError] = useState('');

    useEffect(() => {
        setName(battery.name);
        setSystem(battery.system);
        setSystemSettings(battery?.system_settings ? JSON.stringify(battery.system_settings) : '{}');
        setDeviceLabels(battery?.device_labels ? JSON.stringify(battery.device_labels) : '{}');
        setForecastHorizon(battery.forecast_horizon);
    }, [battery]);

    const updateBattery = async () => {
        setError('');
        try {
            const updatedBattery = {
                name,
                device_labels: JSON.parse(deviceLabels || '{}'),
                system,
                system_settings: JSON.parse(systemSettings || '{}'),
                forecast_horizon: parseInt(forecastHorizon),
            };
            let resp = await api.update_virtual_battery(battery.id, updatedBattery);
            setBattery(resp.data);
            handleClose();
            toast.success(`Virtual battery updated successfully`, {
                position: 'top-center',
                autoClose: 300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.log(error);
            setError(`An error happened during battery update!\n${error}`);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setName(battery.name);
        setSystem(battery.system);
        setSystemSettings(battery?.system_settings ? JSON.stringify(battery.system_settings) : '{}');
        setDeviceLabels(battery?.device_labels ? JSON.stringify(battery.device_labels) : '{}');
        setForecastHorizon(battery.forecast_horizon);
    };

    return (
        <>
            <ToastContainer />
            <Popup open={open} onClose={handleClose} modal className="modal">
                <div>
                    <button className="modal__close" onClick={handleClose}>
                        &#10005;
                    </button>
                    <h5 className="modal__header"> Update Virtual Battery </h5>
                    <div className="modal__content battery-details__edit-modal">
                        <label htmlFor="batteryName">Battery Name</label>
                        <Input
                            id="batteryName"
                            type="text"
                            placeholder="Battery Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            fullWidth
                        />

                        <label htmlFor="forecastHorizon">Forecast Horizon (in periods)</label>
                        <Input
                            id="forecastHorizon"
                            type="number"
                            value={forecastHorizon}
                            placeholder="Forecast Horizon (in number of periods)"
                            onChange={e => setForecastHorizon(e.target.value)}
                            fullWidth
                        />

                        <label htmlFor="system">Trading System</label>
                        <Select id="system" value={system} onChange={e => setSystem(e.target.value)} options={systems} fullWidth />

                        <label htmlFor="systemSettings">System Settings (json)</label>
                        <Input
                            id="systemSettings"
                            type="text"
                            value={systemSettings}
                            placeholder="Trading System Settings"
                            onChange={e => setSystemSettings(e.target.value)}
                            fullWidth
                        />

                        <label htmlFor="deviceLabels">Custom Device Labels (json)</label>
                        <Input
                            id="deviceLabels"
                            type="text"
                            value={deviceLabels}
                            placeholder="Custom Device Labels"
                            onChange={e => setDeviceLabels(e.target.value)}
                            fullWidth
                        />
                    </div>

                    {error !== '' && <p className="batteries__create-modal--error">{error}</p>}

                    <div className="modal__actions">
                        <Button text="Update" onClick={updateBattery} />
                        <Button text="Cancel" onClick={handleClose} className="modal__cancel" />
                    </div>
                </div>
            </Popup>
        </>
    );
};

export default EditVirtualBatteryModal;
