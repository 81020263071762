import React, { useEffect, useState } from 'react';

import logsFunc from '../../components/DeviceHistoryLogs/logs';
import useGetDevicesForCustomer from '../../hooks/useGetDevicesForCustomer';
import Loader from '../../components/Loader';
import { Select } from '../../components/Common';
import { DateTime } from 'luxon';

const TIME_PERIOD = [
    { value: 'all', label: 'All time' },
    { value: '1', label: 'Last day' },
    { value: '30', label: 'Last month' },
    { value: '60', label: 'Last two months' },
    { value: '90', label: 'Last three months' },
];

const DeviceLogs = ({ customerId }) => {
    const [selectedDevice, setSelectedDevice] = useState('');
    const [selectedTime, setSelectedTime] = useState('1');
    const [logsEndDate, setLogsEndDate] = useState(DateTime.now().toISO());
    const [logsStartDate, setLogsStartDate] = useState(DateTime.now().minus({ days: 1 }).toISO());
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState(true);

    const { isReady, data: devices } = useGetDevicesForCustomer(customerId, true);

    useEffect(() => {
        setSelectedTime('1');
        getLogsForDevices(logsStartDate, logsEndDate);
    }, [devices, customerId, selectedDevice]);

    const getLogsForDevices = async (from, to) => {
        if (devices.length !== 0 && selectedDevice === '') {
            setSelectedDevice(devices[0].id);
        }

        if (selectedDevice !== '') {
            const foundLogs = [];
            setLoading(true);

            logsFunc.getLogsForDevice(from, to, selectedDevice).then(data => {
                foundLogs.push(...data);
                setLogs(foundLogs);
                setLoading(false);
            });
        }

        setLogs([]);
    };

    const selectDevice = e => {
        setSelectedDevice(e.target.value);
    };

    const selectTime = async value => {
        setSelectedTime(value);

        let from;
        let to = DateTime.now().toISO();
        if (value === '30') {
            from = DateTime.now().minus({ months: 1 }).toISO();
        } else if (value === '60') {
            from = DateTime.now().minus({ months: 2 }).toISO();
        } else if (value === '90') {
            from = DateTime.now().minus({ months: 3 }).toISO();
        } else if (value === '1') {
            from = DateTime.now().minus({ days: 1 }).toISO();
        }

        await getLogsForDevices(from, to);
    };

    const selectEnd = async value => {
        setLogsEndDate(value);

        if (logsStartDate !== '') {
            let from = new Date(logsStartDate).toISOString();
            let to = new Date(value).toISOString();

            await getLogsForDevices(from, to);
        }
    };

    const selectStart = async value => {
        setLogsStartDate(value);

        if (logsEndDate !== '') {
            let from = new Date(value).toISOString();
            let to = new Date(logsEndDate).toISOString();

            await getLogsForDevices(from, to);
        }
    };

    return (
        <>
            {devices.length !== 0 && (
                <div className="card-wrapper">
                    {loading && isReady ? (
                        <Loader className="customer-details__device-logs--loader" />
                    ) : (
                        <>
                            <div className="customer-details__device-logs">
                                <div className="customer-details__card-header">Device history logs</div>
                                <button className="customer-details__device-logs--expand-btn" onClick={() => setExpanded(!expanded)}>
                                    {expanded ? <span>&#xf106;</span> : <span>&#xf107;</span>}
                                </button>
                            </div>

                            {expanded && (
                                <div className="customer-details__device-logs--container">
                                    <div className="customer-details__device-logs--filters">
                                        <Select
                                            name="device"
                                            onChange={selectDevice}
                                            options={devices.map(dev => {
                                                return { value: dev.id, label: `${dev.name} ${dev.deleted ? `(deleted)` : ''}` };
                                            })}
                                            value={selectedDevice}
                                        />
                                        <Select name="time" onChange={e => selectTime(e.target.value)} value={selectedTime} options={TIME_PERIOD} />

                                        <div className="customer-details__device-logs--date-range">
                                            <input type="date" name="start" value={logsStartDate} onChange={e => selectStart(e.target.value)} />
                                            <p>&#8722;</p>
                                            <input type="date" name="end" value={logsEndDate} onChange={e => selectEnd(e.target.value)} />
                                        </div>
                                    </div>
                                    <p className="customer-details__device-logs--device-number">DEVICE NUMBER: {selectedDevice}</p>
                                    <ul className="customer-details__device-logs--logs-container">
                                        {logs
                                            ?.filter(log => log.device === selectedDevice && !log.hide)
                                            .map((item, i) => (
                                                <li key={i}>
                                                    <div className="customer-details__device-logs--log-header">
                                                        <p className="customer-details__device-logs--log-timestamp">{item.timestamp}</p>
                                                        <p className="customer-details__device-logs--log-owner">{item?.source || 'system'}</p>
                                                    </div>

                                                    <p className="customer-details__device-logs--event">{item.event}</p>
                                                    {item.change && (
                                                        <ul className="customer-details__device-logs--change-list">
                                                            {item.change?.map(i => (
                                                                <li className="customer-details__device-logs--sublist" key={i}>
                                                                    <p>{i}</p>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default DeviceLogs;
