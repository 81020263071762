import React from 'react';
import { BREAKDOWNS } from '../RetailerReporting';
import { Select } from '../../../components/Common';

export const BreakdownSelector = ({ value, onChange }) => {
    return (
        <>
            <span>Breakdown:</span>
            <Select
                className="reporting__filters--input"
                value={value}
                onChange={onChange}
                options={BREAKDOWNS.map(b => {
                    return { value: b.value, label: b.display };
                })}
            />
        </>
    );
};
