import React from 'react';
import { useState } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Loader from '../../components/Loader';
import APICompatibility from './APICompatibility';

const Support = () => {
    const [loading, setLoading] = useState(true);
    const [index, setIndex] = useState(0);

    function renderIFrame(src) {
        return (
            <div className="support__status-page-wrapper">
                <iframe
                    title="status-page"
                    className="support__iframe-status"
                    src={src}
                    onLoad={hideLoader}
                    style={{ display: loading ? 'none' : 'block' }}
                ></iframe>
            </div>
        );
    }

    function showLoader(index) {
        setIndex(index);

        if (index === 2 || index === 1) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }

    function hideLoader() {
        setLoading(false);
    }

    return (
        <div>
            <Tabs className="tabs" selectedIndex={index} onSelect={index => showLoader(index)}>
                <TabList className="tabs__tab-nav">
                    <Tab>Live Status</Tab>
                    <Tab>API Compatibility</Tab>
                    <Tab>FAQ</Tab>
                    <Tab>Create a ticket</Tab>
                </TabList>
                {loading && <Loader />}
                <TabPanel className="support__status-panel">
                    {!loading && <span className="support__third-party-info">This page uses third-party data</span>}
                    {renderIFrame('https://status.emulate.energy/')}
                </TabPanel>
                <TabPanel>
                    <APICompatibility />
                </TabPanel>
                <TabPanel>{renderIFrame('https://www.knowledge.emulate.energy/')}</TabPanel>
                <TabPanel>{renderIFrame('https://share-eu1.hsforms.com/1lDKCKlSWQlKsWq5-L2hMIgfmcj7')}</TabPanel>
            </Tabs>
        </div>
    );
};

export default Support;
