import { Fragment } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const Breadcrumbs = ({ crumbs }) => {
    return (
        <div className="breadcrumbs">
            {crumbs.map(({ name, to }, idx) => (
                <Fragment key={`divider-${idx}`}>
                    <p>{idx > 0 && ' / '} </p>
                    <NavLink className={idx === crumbs.length - 1 ? 'breadcrumbs__last-link' : 'breadcrumbs__link'} exact to={to}>
                        {name}
                    </NavLink>
                </Fragment>
            ))}
        </div>
    );
};

export default Breadcrumbs;
