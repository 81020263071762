import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { toastOptions } from './UserManagement';
import api from '../../api';
import NewUserModal from '../../components/Modal/NewUserModal';
import SelectRolesForm from './SelectRolesForm';
import { Input } from '../../components/Common';

const AddUserForm = ({ roles, getUsers }) => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [newUser, setNewUser] = useState();

    const addUser = async newUserRoleList => {
        if (!email || !firstName || !lastName) {
            toast.error('You must enter the required user information!', { ...toastOptions, autoClose: 800 });
        } else {
            api.create_user(email, firstName, lastName, newUserRoleList).then(
                response => {
                    setNewUser(response.data);
                    setOpenModal(true);
                    getUsers();
                    resetForm();
                },
                error => {
                    toast.error(error.response?.data?.error, { ...toastOptions, autoClose: 800 });
                }
            );
        }
    };

    const resetForm = () => {
        setEmail('');
        setFirstName('');
        setLastName('');
    };

    return (
        <div className="user-management__create-user">
            <form className="user-management__create-user--form">
                <div className="user-management__create-user--fields">
                    <p className="user-management__create-user--input-field">
                        <label>Email</label>
                        <Input type="email" required={true} value={email} onChange={e => setEmail(e.target.value)} placeholder="Email..." />
                    </p>
                    <p className="user-management__create-user--input-field">
                        <label>First Name</label>
                        <Input
                            type="text"
                            required={true}
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            placeholder="First name..."
                        />
                    </p>
                    <p className="user-management__create-user--input-field">
                        <label>Last Name</label>
                        <Input type="text" required={true} value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Last name..." />
                    </p>
                </div>
                <div className="user-management__create-user--roles">
                    <label>Roles</label>
                    <SelectRolesForm allRoles={roles} type="add" action={addUser} userRoles={[]} />
                </div>
            </form>
            <hr />
            <NewUserModal open={openModal} handleClose={() => setOpenModal(false)} user={newUser} />
        </div>
    );
};

export default AddUserForm;
