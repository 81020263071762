import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Chart from 'react-google-charts';
import { getTooltip, granularityToUnitAndFormat } from '../../RetailerReporting';

export const DeactivatedCustomers = ({ granularity, results, dates }) => {
    //Build up data to plot.
    let [unit, format] = granularityToUnitAndFormat(granularity);
    let series = [unit, 'Customers', { role: 'tooltip', type: 'string', p: { html: true } }];
    let data = [series];
    let hasNonZeroData = false;

    const savingsBalanceTooltip = result => {
        let customTooltip = getTooltip(granularity, result);
        return ReactDOMServer.renderToString(
            <div style={{ width: '200px', padding: '8px 10px', display: 'flex', flexDirection: 'column' }}>
                <strong style={{ textAlign: 'center', width: '100%', marginBottom: '4px' }}>{customTooltip}</strong>
                <p style={{ width: '100%' }}>Savings Outcome:</p>
                <ul style={{ width: '100%', margin: 0 }}>
                    {result?.savings.map(s => (
                        <li className="reporting__savings-balance">
                            {s.kind === 'NONE' ? 'No profit or loss' : s.kind.toLowerCase()}: {s.count}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    for (let result of results.DEACTIVATED) {
        if (result.count > 0) {
            hasNonZeroData = true;
        }

        let tooltip = savingsBalanceTooltip(result);
        data.push([new Date(result.start), result.count, tooltip]);
    }

    // Display simple bar chart.
    const options = {
        hAxis: {
            format,
            slantedText: false,
            textStyle: {
                fontSize: 14,
            },
            ...(dates.length > 0 && { ticks: dates }),
        },
        colors: ['red'],
        vAxis: {
            format: '0',
            ...(hasNonZeroData ? {} : { viewWindow: { min: 0, max: 1 } }),
        },
        chartArea: {
            height: '80%',
        },
        tooltip: {
            isHtml: true,
        },
    };
    return (
        <div className="reporting__graph-wrapper--chart">
            <h3 className="reporting__title">Deactivated customers and their savings outcome</h3>
            <Chart chartType="ColumnChart" width="100%" height="400px" data={data} options={options} />
        </div>
    );
};
