import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { ColorPicker, createColor } from 'material-ui-color';
import api from '../../api';
import { Button, Input, Select } from '../../components/Common';
import InfoTooltip from '../../components/InfoTooltip/InfoTooltip';
import cc from 'classcat';

function ensureColorObjects(settings) {
    let result = {};
    for (let key in settings) {
        if (key.includes('color') && typeof settings[key] === 'string') {
            result[key] = createColor(settings[key]);
        } else {
            result[key] = settings[key];
        }
    }
    return result;
}

const themeDefaults = {
    page_title: 'Emulate Energy',
    regular_font: '',
    font_file_name: '',
    primary_color: '#e54f20',
    background_color: '#f2f2f2',
    foreground_color: '#ffffff',
    corner_radius: 4,
    variant: 'outlined',
    button_text_color: '#FFFFFF',
};

const toastOptions = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const BUTTON_STYLE_FILTER_VALUES = [
    { value: 'outlined', label: 'Outlined' },
    { value: 'contained', label: 'Filled In' },
];

const WebViewTheme = () => {
    const [theme, setTheme] = useState(ensureColorObjects(themeDefaults));
    const [originalTheme, setOriginalTheme] = useState(theme);
    const [isEditMode, setEditMode] = useState(false);

    const loadTheme = async () => {
        try {
            const loadedTheme = (await api.web_app_settings()).data || {};
            const mergedTheme = ensureColorObjects({ ...themeDefaults, ...loadedTheme });
            setTheme(mergedTheme);
            setOriginalTheme(mergedTheme);
        } catch (error) {
            toast.error('Failed to load web application theme settings', toastOptions);
        }
    };

    useEffect(() => {
        loadTheme();
    }, []);

    const saveTheme = async () => {
        try {
            let newTheme = { ...theme, corner_radius: parseInt(theme.corner_radius) };

            for (let key in newTheme) {
                if (key.includes('color')) {
                    newTheme[key] = '#' + newTheme[key].hex;
                }
            }
            await api.set_web_app_settings(newTheme);
            setOriginalTheme(newTheme);
            toast.success('Web application theme settings saved', toastOptions);
        } catch (error) {
            toast.error('Failed to save web application theme settings', toastOptions);
        }
    };

    const enterEditMode = () => {
        setOriginalTheme(theme);
        setEditMode(true);
    };

    const resetToDefaults = () => {
        setTheme(ensureColorObjects(themeDefaults));
    };

    const cancelEdit = () => {
        setTheme(originalTheme);
        setEditMode(false);
    };

    const handleFileChange = e => {
        let file = e.target.files[0];

        let reader = new FileReader();
        reader.addEventListener('load', done => {
            let bytes = new Uint8Array(done.target.result);
            let binary = '';
            let len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            let base64 = window.btoa(binary);
            setTheme({ ...theme, regular_font_file: base64, font_file_name: file.name });
        });
        reader.readAsArrayBuffer(file);
    };

    return (
        <>
            <ToastContainer />
            <div>
                <div className={cc(['customer-web-view__custom-title', isEditMode && 'customer-web-view__custom-title--edit-mode'])}>
                    <h5 style={{ margin: 0 }}>Web View Theme Settings</h5>
                    {!isEditMode ? (
                        <button onClick={enterEditMode}>
                            <img src="/assets/icons/edit-button.svg" alt="Edit Settings" />
                        </button>
                    ) : (
                        <div className="customer-web-view__custom-title--reset">
                            <Button text="Reset to Defaults" className="customer-web-view__reset-button" isSecondary onClick={resetToDefaults} />
                            <InfoTooltip tooltip="Reset your web view theme to default Emulate theme settings" />
                        </div>
                    )}
                </div>
                <div className="customer-web-view__settings">
                    <div className="customer-web-view__information-section customer-web-view__information-section--small-padding">
                        <div className="customer-web-view__information-section--columns">
                            {/* ----- COLORS ----- */}
                            <div className="customer-web-view__info-field">
                                <p className="customer-web-view__info-field--label">Primary Color:</p>
                                <div className="customer-web-view__info-field--value-wrapper">
                                    {isEditMode ? (
                                        <ColorPicker
                                            value={theme.primary_color}
                                            onChange={e =>
                                                setTheme({
                                                    ...theme,
                                                    primary_color: typeof e === 'string' ? createColor(e) : e,
                                                })
                                            }
                                        />
                                    ) : (
                                        <div className="customer-web-view__info-field--color">
                                            <span style={{ backgroundColor: `#${theme.primary_color.hex}` }}></span>
                                            <p className="customer-web-view__info-field--value">#{theme.primary_color.hex || '-'}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="customer-web-view__info-field">
                                <p className="customer-web-view__info-field--label">Background Color:</p>
                                <div className="customer-web-view__info-field--value-wrapper">
                                    {isEditMode ? (
                                        <ColorPicker
                                            value={theme.background_color}
                                            onChange={e =>
                                                setTheme({
                                                    ...theme,
                                                    background_color: typeof e === 'string' ? createColor(e) : e,
                                                })
                                            }
                                        />
                                    ) : (
                                        <div className="customer-web-view__info-field--color">
                                            <span style={{ backgroundColor: `#${theme.background_color.hex}` }}></span>
                                            <p className="customer-web-view__info-field--value">#{theme.background_color.hex || '-'}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="customer-web-view__info-field">
                                <p className="customer-web-view__info-field--label">Foreground Color:</p>
                                <div className="customer-web-view__info-field--value-wrapper">
                                    {isEditMode ? (
                                        <ColorPicker
                                            value={theme.foreground_color}
                                            onChange={e =>
                                                setTheme({
                                                    ...theme,
                                                    foreground_color: typeof e === 'string' ? createColor(e) : e,
                                                })
                                            }
                                        />
                                    ) : (
                                        <div className="customer-web-view__info-field--color">
                                            <span style={{ backgroundColor: `#${theme.foreground_color.hex}` }}></span>
                                            <p className="customer-web-view__info-field--value">#{theme.foreground_color.hex || '-'}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* ----- FONTS ----- */}
                            <div className="customer-web-view__info-field">
                                <div className="customer-web-view__info-field--tooltip">
                                    <InfoTooltip tooltip="Select the typeface to use for text styling. This determines the look of the text." />
                                    <p className="customer-web-view__info-field--label">Font Family:</p>
                                </div>
                                <div className="customer-web-view__info-field--value-wrapper">
                                    {isEditMode ? (
                                        <Input
                                            fullWidth
                                            placeholder="e.g., Arial, Roboto, Times New Roman"
                                            type="text"
                                            value={theme.regular_font}
                                            onChange={e => setTheme({ ...theme, regular_font: e.target.value })}
                                        />
                                    ) : (
                                        <p className="customer-web-view__info-field--value">{theme.regular_font || '-'}</p>
                                    )}
                                </div>
                            </div>
                            <div className="customer-web-view__info-field">
                                <div className="customer-web-view__info-field--tooltip">
                                    <InfoTooltip tooltip="File should be in `.ttf`, `.otf`, `.woff` or `.woff2` format." />
                                    <p className="customer-web-view__info-field--label">Font File:</p>
                                </div>
                                <div className="customer-web-view__info-field--value-wrapper">
                                    {isEditMode ? (
                                        theme.regular_font_file ? (
                                            <div className="customer-web-view__documents--document-container">
                                                <div className="customer-web-view__documents--document">
                                                    <div className="customer-web-view__documents--description">
                                                        <img
                                                            className="customer-web-view__documents--doc-image"
                                                            src="/assets/icons/document.svg"
                                                            alt="Doc"
                                                        />
                                                        <p className="customer-web-view__documents--description--title">{theme.font_file_name}</p>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() => setTheme({ ...theme, regular_font_file: '' })}
                                                    className="customer-web-view__documents--delete-button"
                                                >
                                                    <img
                                                        className="customer-web-view__documents--delete-image"
                                                        src="/assets/icons/close.svg"
                                                        alt="Remove Document"
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <label className="customer-web-view__documents--upload--label" htmlFor="document-upload">
                                                <div className="customer-web-view__documents--upload">
                                                    <img src="/assets/icons/document-upload.svg" alt="Upload" />
                                                    Upload Font File
                                                </div>
                                                <input
                                                    id="document-upload"
                                                    type="file"
                                                    onChange={e => handleFileChange(e, 'regular_font_file')}
                                                    style={{ display: 'none' }}
                                                />
                                            </label>
                                        )
                                    ) : (
                                        <p className="customer-web-view__info-field--value">{theme.regular_font_file ? theme.font_file_name : '-'}</p>
                                    )}
                                </div>
                            </div>
                            <div></div>
                            {/* ----- MISCELLANEOUS ----- */}
                            <div className="customer-web-view__info-field">
                                <div className="customer-web-view__info-field--tooltip">
                                    <InfoTooltip tooltip="This setting will apply to buttons, toggles, switches, cards and similar elements" />
                                    <p className="customer-web-view__info-field--label">Corner Radius:</p>
                                </div>
                                <div className="customer-web-view__info-field--value-wrapper">
                                    {isEditMode ? (
                                        <Input
                                            fullWidth
                                            type="text"
                                            value={theme.corner_radius}
                                            onChange={e => setTheme({ ...theme, corner_radius: e.target.value })}
                                        />
                                    ) : (
                                        <p className="customer-web-view__info-field--value">{theme.corner_radius || '-'}</p>
                                    )}
                                </div>
                            </div>
                            <div className="customer-web-view__info-field">
                                <p className="customer-web-view__info-field--label">Button Style:</p>
                                <div className="customer-web-view__info-field--value-wrapper">
                                    {isEditMode ? (
                                        <Select
                                            value={theme.variant || ''}
                                            options={BUTTON_STYLE_FILTER_VALUES}
                                            onChange={e => setTheme({ ...theme, variant: e.target.value })}
                                            className="customer-web-view__info-field--select"
                                        />
                                    ) : (
                                        <p className="customer-web-view__info-field--value">
                                            {theme.variant === 'contained' ? 'Filled In' : theme.variant === 'outlined' ? 'Outlined' : '-'}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="customer-web-view__info-field">
                                <div className="customer-web-view__info-field--tooltip">
                                    <InfoTooltip tooltip="This setting will only be applied if button style is set to `Filled In`" />
                                    <p className="customer-web-view__info-field--label">Button Text Color:</p>
                                </div>
                                <div className="customer-web-view__info-field--value-wrapper">
                                    {isEditMode ? (
                                        <ColorPicker
                                            value={theme.button_text_color}
                                            onChange={e =>
                                                setTheme({
                                                    ...theme,
                                                    button_text_color: typeof e === 'string' ? createColor(e) : e,
                                                })
                                            }
                                        />
                                    ) : (
                                        <div className="customer-web-view__info-field--color">
                                            <span style={{ backgroundColor: `#${theme.button_text_color.hex}` }}></span>
                                            <p className="customer-web-view__info-field--value">#{theme.button_text_color.hex || '-'}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isEditMode && (
                    <>
                        <p className="customer-web-view__warning">
                            * Please note that clicking <i>“Save”</i> will immediately apply all settings, impacting all end-users.
                        </p>
                        <div className="customer-web-view__save-settings">
                            <Button
                                text="Save"
                                onClick={() => {
                                    saveTheme();
                                    setEditMode(false);
                                }}
                            />
                            <Button text="Cancel" isSecondary className="customer-web-view__save-settings--cancel" onClick={cancelEdit} />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default WebViewTheme;
