import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Layout from './hocs/Layout';
import getRoutes from './routes';

class App extends Component {
    render() {
        const availableRoles = this.props.availableRoles;
        const availableRoutes = getRoutes(availableRoles);
        return (
            <BrowserRouter>
                <Layout traderName={this.props.traderName} availableRoutes={availableRoutes} logout={this.props.logout}>
                    <Switch>
                        {availableRoutes.map(({ path, component }) => (
                            <Route exact path={path} component={component} key={path} />
                        ))}
                        <Redirect from="/" to={availableRoutes[0].path} />
                    </Switch>
                </Layout>
            </BrowserRouter>
        );
    }
}

export default App;
