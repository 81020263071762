import React from 'react';
import cc from 'classcat';

export const Input = ({ className, type, placeholder, name, id, value, onChange, onKeyDown, fullWidth, required }) => {
    return (
        <input
            className={cc(['default-input', className, fullWidth && 'default-input__full-width'])}
            type={type}
            placeholder={placeholder}
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            required={required}
        />
    );
};
