import React, { useEffect, useMemo, useState } from 'react';

import { retailerReportingQuery } from '../../../api';
import { GRANULARITIES, xAxisLabels } from '../RetailerReporting';
import useEndDateValidation from '../useEndDateValidation';

import { CustomerDeviceCountDistributionGraph, DeactivatedCustomers, EVAndChargerDistributionGraph } from './Graphs';
import { GranularitySelector, DateRangeSelector } from '../Selectors';

const Customers = () => {
    let [granularity, setGranularity] = useState(GRANULARITIES[2].value);
    let [from, setFrom] = useState(null);
    const [to, validateAndSetDate] = useEndDateValidation();
    let [queryResults, setQueryResults] = useState({});

    useEffect(() => {
        (async function () {
            setQueryResults(
                await retailerReportingQuery(
                    `query DeviceEventCount($granularity: Granularity!, $from: Date, $to: Date) {
                  DISTRIBUTION: customerDeviceDistribution(granularity: $granularity, from: $from, to: $to) {
                    start,
                    devices {
                      devices,
                      count
                    },
                    types {
                      types,
                      count,
                      customer
                    }
                  },
                  DEACTIVATED: deactivatedCustomers(granularity: $granularity, from: $from, to: $to) {
                    start,
                    count,
                    savings {
                        kind,
                        count
                    }
                  }
                }`,
                    { granularity, from, to }
                )
            );
        })();
    }, [granularity, from, to]);

    const dates = useMemo(() => {
        return xAxisLabels(queryResults[Object.keys(queryResults)[0]]);
    }, [queryResults]);

    return (
        <>
            <div className="reporting__filters">
                <GranularitySelector
                    value={granularity}
                    onChange={e => {
                        setQueryResults({});
                        setGranularity(e.target.value);
                    }}
                />
                <DateRangeSelector
                    fromValue={from}
                    toValue={to}
                    onFromChange={date => setFrom(date)}
                    onToChange={date => validateAndSetDate(date, granularity)}
                    granularity={granularity}
                />
            </div>

            <div className="reporting__graph-wrapper">
                {queryResults['DISTRIBUTION']?.length > 0 && (
                    <CustomerDeviceCountDistributionGraph granularity={granularity} results={queryResults} dates={dates} />
                )}
                {queryResults['DISTRIBUTION']?.length > 0 && (
                    <EVAndChargerDistributionGraph granularity={granularity} results={queryResults} dates={dates} />
                )}
                {queryResults['DEACTIVATED']?.length > 0 && <DeactivatedCustomers granularity={granularity} results={queryResults} dates={dates} />}
            </div>
        </>
    );
};

export default Customers;
