import React from 'react';

import { granularityToUnitAndFormat } from '../../RetailerReporting';
import Chart from 'react-google-charts';
import { manufacturerOrModelBreakdownTooltip } from '../Devices';

export const DeviceEventGraphByManufacturer = ({ results, granularity, dates }) => {
    // Find all manufacturers and build up the series.
    let [unit, format] = granularityToUnitAndFormat(granularity);
    let series = [unit];
    let manufacturerSeriesIndices = {};
    let hasNonZeroData = false;

    for (let datapoint of results) {
        if (datapoint.count > 0) {
            hasNonZeroData = true;
        }
        for (let type of datapoint.types) {
            for (let manufacturer of type.manufacturers) {
                if (!manufacturerSeriesIndices[manufacturer.name]) {
                    manufacturerSeriesIndices[manufacturer.name] = series.length;
                    series.push(manufacturer.name);
                    series.push({ role: 'tooltip', type: 'string', p: { html: true } });
                }
            }
        }
    }

    // Can't render a graph at all if there's no manufacturers.
    if (!hasNonZeroData) {
        return <p className="reporting__no-data-message">No data of this type to show yet.</p>;
    }

    // Now transform the data to be per manufacturer.
    let data = [series];
    for (let datapoint of results) {
        // Initialize zero value and empty tooltip for all manufacturers.
        let bar = [new Date(datapoint.start)];
        for (let i = 0; i < Object.keys(manufacturerSeriesIndices).length; i++) {
            bar.push(0);
            bar.push('');
        }
        data.push(bar);

        // Add manufacturer data counts. For now, we assume that a manufacturer
        // only does one device type for the tooltip handling.
        for (let type of datapoint.types) {
            for (let manufacturer of type.manufacturers) {
                let index = manufacturerSeriesIndices[manufacturer.name];
                bar[index] += manufacturer.count;
                bar[index + 1] = manufacturerOrModelBreakdownTooltip(manufacturer.models, manufacturer.customer);
            }
        }
    }

    // Display stacked bar chart.
    const options = {
        isStacked: true,
        hAxis: {
            format,
            slantedText: false,
            textStyle: {
                fontSize: 14,
            },
            ...(dates.length > 0 && { ticks: dates }),
        },
        vAxis: {
            format: '0',
        },
        tooltip: {
            isHtml: true,
        },
        chartArea: {
            height: '80%',
        },
    };
    return <Chart chartType="ColumnChart" width="100%" height="400px" data={data} options={options} />;
};
