import { useMemo } from 'react';
export const DOTS = '...';

const setPages = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, i) => i + start);
};

export const usePagination = ({ total, elementsPerPage, currentPage }) => {
    const paginationRange = useMemo(() => {
        const siblingCount = 1; //always show at least one more page next to the active one
        const maxVisiblePages = 7;
        const totalPages = Math.ceil(total / elementsPerPage);

        if (maxVisiblePages >= totalPages) {
            return setPages(1, totalPages);
        }

        const leftSibling = currentPage - siblingCount;
        const rightSibling = currentPage + siblingCount;

        const shouldShowLeftDots = leftSibling > 3;
        const shouldShowRightDots = rightSibling < totalPages - 2;
        const firstPage = 1;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let left = setPages(1, 5); //show the first 5 pages
            return [...left, DOTS, totalPages];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let right = setPages(totalPages - 4, totalPages); //show the last 5 pages
            return [firstPage, DOTS, ...right];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middle = setPages(leftSibling, rightSibling); //show the first, the last page and the middle three
            return [firstPage, DOTS, ...middle, DOTS, totalPages];
        }
    }, [total, elementsPerPage, currentPage]);

    return paginationRange;
};
