import { createGraphiQLFetcher } from '@graphiql/toolkit';
import React from 'react';
import { graphQLUrl } from '../../../api';
import GraphiQL from 'graphiql';

const Advanced = () => {
    const fetcher = createGraphiQLFetcher({
        url: graphQLUrl(),
        headers: {
            Authorization: `Bearer ${window.auth_token}`,
        },
    });
    return (
        <>
            <p>
                Write custom{' '}
                <a href="https://graphql.org/" target="_blank" rel="noreferrer">
                    GraphQL queries
                </a>{' '}
                to extract data in JSON format.
            </p>
            <div className="reporting__graphql-input">
                <GraphiQL fetcher={fetcher} />
            </div>
        </>
    );
};

export default Advanced;
