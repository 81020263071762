import api from '../../api';

export async function getLogsForDevice(from, to, deviceId) {
    // Obtain both SPO and BTM logs and combine them.
    let spoLogs = (await api.get_spo_logs(deviceId, from, to)).data;
    let connectLogs = (await api.get_connect_logs(deviceId, from, to)).data;
    let logsForDevice = spoLogs
        .concat(connectLogs)
        .map(l => ({ ...l, timestamp: new Date(l.timestamp) }))
        .sort((a, b) => {
            let at = a.timestamp.getTime();
            let bt = b.timestamp.getTime();
            return bt - at;
        });

    // Format the log events and timestamps.
    logsForDevice.forEach(log => {
        log.device = deviceId;
        log.timestamp = log.timestamp.toLocaleString('se-SE');
        log.hide = false;

        switch (log.event) {
            case 'onboarding-success':
                log.event =
                    'A connection with the device was successfully established. Now it will be analyzed to determine how it can be spot price optimized.';
                break;
            case 'onboarding-failure':
                log.event = 'A connection with the device could not be established. Contact Emulate support for further assistance.';
                break;
            case 'sampling-error':
                log.event = 'A problem with obtaining data from the device has been detected.';
                break;
            case 'recovered':
                log.event = 'Communication with the device has recovered.';
                break;
            case 'backoff': {
                log.event = 'There are persistent problems obtaining data from the device.';
                if (log.data.reactivation_time) {
                    let until = new Date(log.data.reactivation_time * 1000).toLocaleString('se-SE');
                    log.event += ` Giving up until ${until}.`;
                }
                break;
            }
            case 'lost':
                log.event = 'The connection with the device is considered to be lost. It will need to be reconnected.';
                break;
            case 'deleted':
                log.event = 'The device has been removed.';
                break;
            case 'command-error':
                log.event = 'It was not possible to send a command to the device, for example to control charging or heating.';
                break;
            case 'spo-on':
                log.event = 'Spot price optimization for the device has been turned on.';
                break;
            case 'spo-change':
                log.event = 'Spot price optimization settings have been changed. ';
                log.change = changeMessages(log);
                break;
            case 'unsupported':
                log.event = `Spot price optimization of this device is not supported. ${log.data.reason}`;
                break;
            case 'excitation-complete':
                log.event = 'Analysis of the device is complete and it spot price optimization can now take place.';
                break;
            case 'excitation-failed':
                log.event = 'Analysis of the device failed and it cannot be spot price optimized. Contact Emulate support for further assistance.';
                break;
            case 'spo-off':
                log.event = 'Spot price optimization for the device has been turned off.';
                break;
            case 'refresh-failed':
                log.hide = true;
                break;
            case 'refresh-recovered':
                log.hide = true;
                break;
            case 'response-time-measurement':
                log.event = 'Obtained data to identify model parameters.';
                log.hide = true;
                break;
            case 'aborted-experiment':
                log.event = 'Unable to obtain data for identifying model parameters.';
                log.hide = true;
                break;
            default:
                log.event = 'Unknown event, please contact support.';
                break;
        }
    });

    return logsForDevice;
}

function changeMessages(log) {
    let changes = [];
    for (const [key, value] of Object.entries(log.data)) {
        changes.push(`${key}: ${value}`);
    }
    return changes;
}

const exportedObject = {
    getLogsForDevice,
};

export default exportedObject;
