import React from 'react';
import Popup from 'reactjs-popup';

const InfoTooltip = props => {
    return (
        <Popup
            trigger={_ => (
                <div className="info-tooltip__icon">
                    <img src="/assets/icons/info.png" alt="" />
                </div>
            )}
            position="bottom left"
            closeOnDocumentClick
            on={['hover', 'focus']}
            className="info-tooltip"
            arrow={false}
            id="tooltip"
        >
            <span>{props.tooltip}</span>
        </Popup>
    );
};

export default InfoTooltip;
