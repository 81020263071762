import React from 'react';
import Popup from 'reactjs-popup';
import api from '../../api';
import { toast } from 'react-toastify';
import { toastOptions } from '../../containers/UserManagement/UserManagement';
import SelectRolesForm from '../../containers/UserManagement/SelectRolesForm';

const ChangeRolesModal = ({ email, allRoles, userRoles, getUsers, open, setOpen }) => {
    const updateRoles = async newUserRoleList => {
        api.update_roles(email, newUserRoleList).then(
            () => {
                toast.success(`Roles updated for ${email}`, { ...toastOptions, autoClose: 600, onClose: () => setOpen(false) });
                getUsers();
            },
            () => {
                toast.error(`An error happened while updating the roles!`, { ...toastOptions, autoClose: 800 });
            }
        );
    };

    return (
        <Popup open={open} setOpen={() => setOpen(false)} modal nested className="modal change-roles-modal">
            <div>
                <button
                    className="modal__close"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    &#10005;
                </button>
                <div className="modal__header">
                    Change the role for user <b>{email}</b>
                </div>
                <SelectRolesForm allRoles={allRoles} type={'update'} action={updateRoles} userRoles={userRoles} />
            </div>
        </Popup>
    );
};

export default ChangeRolesModal;
