import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import api from '../../api';
import { toast } from 'react-toastify';
import { BatteryTypes } from './Batteries';
import { Button, Input, Select } from '../../components/Common';

const CreateVirtualBatteryModal = ({ open, setOpen, addBatteryToList }) => {
    const priceAreas = [
        { value: '', label: 'Price Area', hidden: true },
        { value: 'SE1', label: 'SE1' },
        { value: 'SE2', label: 'SE2' },
        { value: 'SE3', label: 'SE3' },
        { value: 'SE4', label: 'SE4' },
    ];
    const systems = [
        { value: '', label: 'Trading System', hidden: true },
        { value: 'trayport', label: 'trayport' },
    ];
    const markets = [
        { value: '', label: 'Select Market', hidden: true },
        { value: 'fcr-n', label: 'fcr-n' },
        { value: 'fcr-d-up', label: 'fcr-d-up' },
        { value: 'fcr-d-down', label: 'fcr-d-down' },
        { value: 'intraday', label: 'intraday' },
    ];

    const batteryTypes = Object.entries(BatteryTypes).map(([key, value]) => ({ value: key, label: value }));

    const [name, setName] = useState('');
    const [market, setMarket] = useState('fcr-n');
    const [type, setType] = useState('real_devices');
    const [retailer, setRetailer] = useState('');
    const [priceArea, setPriceArea] = useState('SE1');
    const [deviceLabels, setDeviceLabels] = useState('{}');
    const [system, setSystem] = useState('');
    const [systemSettings, setSystemSettings] = useState('{}');
    const [forecastHorizon, setForecastHorizon] = useState(24 * 2 * 4);
    const [error, setError] = useState('');

    const createBattery = async () => {
        setError('');
        try {
            const new_battery = {
                name,
                market,
                type,
                retailer,
                price_area: priceArea,
                device_labels: JSON.parse(deviceLabels),
                system,
                system_settings: JSON.parse(systemSettings),
                forecast_horizon: parseInt(forecastHorizon),
            };
            let resp = await api.create_virtual_battery(new_battery);
            handleClose();
            addBatteryToList(resp.data);
            toast.success('Created the new battery successfully!');
        } catch (error) {
            setError(`${error.response?.data?.error || 'Something went wrong!'}`);
            toast.error(`Failed to create the battery!\n${error.response?.data?.error || ''}`);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setName('');
        setMarket('fcr-n');
        setDeviceLabels('{}');
        setSystem('');
        setSystemSettings('{}');
        setForecastHorizon(24 * 2 * 4);
    };

    return (
        <Popup open={open} onClose={handleClose} modal className="modal batteries__create-modal">
            <div>
                <button className="modal__close" onClick={handleClose}>
                    &#10005;
                </button>
                <h5 className="modal__header"> Create Virtual Battery </h5>
                <div className="batteries__create-modal--content">
                    <div className="batteries__create-modal--content-column">
                        <label htmlFor="batteryName">Battery Name</label>
                        <Input
                            id="batteryName"
                            type="text"
                            value={name}
                            placeholder="Battery Name"
                            onChange={e => setName(e.target.value)}
                            fullWidth
                        />

                        <label htmlFor="market">Market</label>
                        <Select id="market" value={market} onChange={e => setMarket(e.target.value)} options={markets} fullWidth />

                        <label htmlFor="type">Type</label>
                        <Select id="type" value={type} onChange={e => setType(e.target.value)} options={batteryTypes} fullWidth />

                        <label htmlFor="priceArea">Price Area</label>
                        <Select id="priceArea" value={priceArea} onChange={e => setPriceArea(e.target.value)} options={priceAreas} fullWidth />

                        <label htmlFor="retailer">Retailer</label>
                        <Input
                            id="retailer"
                            type="text"
                            value={retailer}
                            placeholder="Retailer"
                            onChange={e => setRetailer(e.target.value)}
                            fullWidth
                        />
                    </div>

                    <div className="batteries__create-modal--content-column">
                        <label htmlFor="forecastHorizon">Forecast Horizon (in periods)</label>
                        <Input
                            id="forecastHorizon"
                            type="number"
                            value={forecastHorizon}
                            placeholder="Forecast Horizon (in number of periods)"
                            onChange={e => setForecastHorizon(e.target.value)}
                            fullWidth
                        />

                        <label htmlFor="system">Trading System</label>
                        <Select id="system" value={system} onChange={e => setSystem(e.target.value)} options={systems} fullWidth />

                        <label htmlFor="systemSettings">System Settings (json)</label>
                        <Input
                            id="systemSettings"
                            type="text"
                            placeholder="Trading System Settings"
                            value={systemSettings}
                            onChange={e => setSystemSettings(e.target.value)}
                            fullWidth
                        />

                        <label htmlFor="deviceLabels">Custom Device Labels (json)</label>
                        <Input
                            id="deviceLabels"
                            type="text"
                            placeholder="Custom Device Labels"
                            value={deviceLabels}
                            onChange={e => setDeviceLabels(e.target.value)}
                            fullWidth
                        />
                    </div>
                </div>

                {error !== '' && <p className="batteries__create-modal--error">{error}</p>}

                <div className="modal__actions">
                    <Button text="Create" onClick={createBattery} />
                    <Button className="modal__cancel" text="Cancel" onClick={handleClose} />
                </div>
            </div>
        </Popup>
    );
};

export default CreateVirtualBatteryModal;
