import axios from 'axios';
import { DateTime } from 'luxon';

function apply_auth(target) {
    target.interceptors.request.use(
        config => {
            config.headers['Authorization'] = `Bearer ${window.auth_token || 'dummy_token'}`;
            return config;
        },
        error => {
            Promise.reject(error);
        }
    );
    return target;
}

function handle_response(response) {
    return response.data;
}

// Retailer API

let baseURL = process.env.REACT_APP_TRADER_API_URL || 'http://localhost:4000/v1/retailer';
const client = apply_auth(axios.create({ baseURL }));

export function places() {
    return client.get('/facilities').then(handle_response);
}

export function place(facility_id) {
    return client.get(`/facilities/${facility_id}`).then(handle_response);
}

export function devices(facility_id) {
    return client.get(`/facilities/${facility_id}/devices`).then(handle_response);
}

export function device_insights(device_id) {
    return client.get(`/devices/${device_id}/insights`).then(handle_response);
}

export function stats() {
    return client.get('/devices/count/30').then(handle_response);
}

export function customer_numbers(include_deleted, criteria, search) {
    let query = '/customers';

    if (include_deleted !== undefined) {
        query += `?include_deleted=${include_deleted}`;
    }

    if (criteria !== undefined) {
        query += `&criteria=${encodeURIComponent(criteria)}`;
    }

    if (search) {
        query += `&search=${encodeURIComponent(search)}`;
    }

    return client.get(query).then(handle_response);
}

export function customer_facilities(customer_id) {
    return client.get(`/customers/${customer_id}/facilities`).then(handle_response);
}

export function customer_web_app_link(customer_id, duration, version) {
    return client
        .get(`/customers/${customer_id}/web_app_link`, {
            params: {
                duration,
                version,
            },
        })
        .then(handle_response);
}

export function web_app_settings() {
    return client.get('/web_app_settings').then(handle_response);
}

export function set_web_app_settings(settings) {
    return client.put('/web_app_settings', { settings });
}

export function get_spo_logs(device_id, from, to) {
    let query = `/spo_log/${device_id}`;
    if (from) {
        query += `?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`;
    }

    return client.get(query).then(handle_response);
}

export function get_connect_logs(device_id, from, to) {
    let query = `/device_log/${device_id}`;
    if (from) {
        query += `?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`;
    }

    return client.get(query).then(handle_response);
}

export function get_devices_for_customer(customer_id, include_deleted) {
    let query = `/customers/${customer_id}/devices`;
    if (include_deleted) {
        query += `?include_deleted=${include_deleted}`;
    }

    return client.get(query).then(handle_response);
}

export function device_spo(device_id) {
    return client.get(`devices/${device_id}/spo`).then(handle_response);
}

export function delete_facility(facility_id) {
    return client.delete(`facilities/${facility_id}`).then(handle_response);
}

export function create_facility(customer_id, retailer_facility_id, address, price_area) {
    return client.post(`/customers/${customer_id}/facilities`, [{ retailer_facility_id, address, price_area }]).then(handle_response);
}

export function get_manufacturers() {
    return client.get('/settings/manufacturers').then(handle_response);
}

export function set_manufacturers(manufacturers) {
    return client.post('/settings/manufacturers', manufacturers).then(handle_response);
}

export function get_manufacturers_and_models(limit, offset, sortBy, sortDirection, manufacturer) {
    return client
        .get('/manufacturers_with_models', {
            params: {
                limit,
                offset,
                sortBy,
                sortDirection,
                manufacturer,
            },
            paramsSerializer: params => {
                const encodedParams = Object.keys(params)
                    .map(key => {
                        if (params[key]) {
                            return `${key}=${encodeURIComponent(params[key])}`;
                        }

                        return '';
                    })
                    .filter(param => param)
                    .join('&');
                return encodedParams;
            },
        })
        .then(handle_response);
}

export function graphQLUrl() {
    return baseURL + '/reporting';
}

export async function retailerReportingQuery(query, variables = {}) {
    let from = variables.from;
    let to = variables.to;
    if (from) {
        from = DateTime.utc(variables.from.getFullYear(), variables.from.getMonth() + 1, variables.from.getDate()).toJSDate();
    }

    if (to) {
        to = DateTime.utc(variables.to.getFullYear(), variables.to.getMonth() + 1, variables.to.getDate()).toJSDate();
    }

    variables = {
        ...variables,
        from,
        to,
    };

    let res = await client.post(`/reporting`, { query, variables });
    let body = res.data;
    if (body.errors) {
        console.error(body.errors);
        throw new Error(`GraphQL query failed: ${JSON.stringify(body.errors)}`);
    }
    return body.data;
}

// Base API

const baseClient = apply_auth(
    axios.create({
        baseURL: process.env.REACT_APP_BASE_API_URL || 'http://localhost:4000/v1',
    })
);

export function get_name_and_roles() {
    return baseClient.get('/whoami').then(response => {
        let body = response.data.data;

        const name = [body.retailer?.name, body.brp?.name].filter(x => x !== undefined).join(' | ');
        const roles = {
            retailer: new Set(body.retailer?.roles),
            brp: new Set(body.brp?.roles),
        };

        return {
            name,
            roles,
            retailer: body.retailer.id,
        };
    });
}

// BRP API

export function get_virtual_batteries() {
    return baseClient.get('brp/batteries').then(handle_response);
}

export function create_virtual_battery(new_battery) {
    return baseClient.post('brp/batteries', new_battery).then(handle_response);
}

export function delete_virtual_battery(battery_id) {
    return baseClient.delete(`brp/batteries/${battery_id}`).then(handle_response);
}

export function update_virtual_battery(battery_id, battery_update) {
    return baseClient.post(`brp/batteries/${battery_id}`, battery_update).then(handle_response);
}

export function get_virtual_battery(battery_id) {
    return baseClient.get(`brp/batteries/${battery_id}`).then(handle_response);
}

export function get_battery_charge_schedule(battery_id) {
    return baseClient.get(`brp/batteries/${battery_id}/charge_schedule`).then(handle_response);
}

export function clear_battery_charge_schedule(battery_id) {
    return baseClient.delete(`brp/batteries/${battery_id}/charge_schedule`).then(handle_response);
}

export function override_battery_charge_schedule(battery_id, new_schedule) {
    return baseClient.put(`brp/batteries/${battery_id}/charge_schedule`, new_schedule).then(handle_response);
}

export function get_battery_forecast(battery_id) {
    return baseClient.get(`brp/batteries/${battery_id}/charge_schedule`).then(handle_response);
}

export function get_battery_delivered(battery_id) {
    return baseClient.get(`brp/batteries/${battery_id}/delivered`).then(handle_response);
}

export function get_fcr_sessions_for_battery(battery_id) {
    return baseClient.get(`brp/fcr/sessions?battery=${battery_id}`).then(handle_response);
}

export function get_fcr_session(session_id) {
    return baseClient.get(`brp/fcr/sessions/${session_id}`).then(handle_response);
}

export function get_fcr_session_log(session_id) {
    return baseClient.get(`brp/fcr/sessions/${session_id}/log`).then(handle_response);
}

// API key management

const apiKeyClient = apply_auth(
    axios.create({
        baseURL: process.env.REACT_APP_API_KEY_URL || 'http://localhost:4000/v1/api-keys',
    })
);

export function api_keys(realm) {
    return apiKeyClient.get(`/${realm}`).then(handle_response);
}

export function revoke_api_key(realm, id) {
    return apiKeyClient.delete(`/${realm}/${id}`).then(handle_response);
}

export function upload_api_public_key(realm, name, roles, key) {
    return apiKeyClient.post(`/${realm}`, { name, roles, key }).then(handle_response);
}

export function generate_api_key_pair(realm, name, roles) {
    return apiKeyClient.post(`/${realm}`, { name, roles }).then(handle_response);
}

// User management

const userManagementClient = apply_auth(
    axios.create({
        baseURL: process.env.REACT_APP_USER_MANAGEMENT_URL || 'http://localhost:4000/v1/users',
    })
);

export async function get_retailer_users() {
    return userManagementClient.get(`/retailer`).then(handle_response);
}

export async function get_retailer_roles() {
    return userManagementClient.get(`/roles/retailer`).then(handle_response);
}

export async function set_roles(email, role_list) {
    return userManagementClient.post(`/retailer/${encodeURI(email)}/roles`, { role_list }).then(handle_response);
}

export async function reset_password(email) {
    return userManagementClient.post(`/retailer/${encodeURI(email)}/password`).then(handle_response);
}

export async function create_user(email, firstname, lastname, roles) {
    return userManagementClient
        .post(`/retailer`, {
            email,
            firstname,
            lastname,
            roles,
        })
        .then(handle_response);
}

export async function delete_user(email) {
    return userManagementClient.delete(`/retailer/${encodeURI(email)}`).then(handle_response);
}

export async function update_roles(email, roles) {
    return userManagementClient.post(`/retailer/${encodeURI(email)}/roles`, roles).then(handle_response);
}

export async function is_mfa_enforced(realm) {
    return userManagementClient
        .get(`/mfa/${realm}`)
        .then(handle_response)
        .then(d => d.data.enabled);
}

export async function enforce_mfa(realm) {
    return userManagementClient.post(`/mfa/${realm}`);
}

export async function get_active_announcements() {
    return baseClient.get('/announcements/active').then(handle_response);
}

const exportedObject = {
    place,
    places,
    devices,
    stats,
    customer_numbers,
    customer_facilities,
    customer_web_app_link,
    web_app_settings,
    set_web_app_settings,
    api_keys,
    revoke_api_key,
    upload_api_public_key,
    generate_api_key_pair,
    get_spo_logs,
    get_connect_logs,
    get_devices_for_customer,
    device_spo,
    delete_facility,
    create_facility,
    get_manufacturers,
    set_manufacturers,
    graphQLUrl,
    retailerReportingQuery,
    get_name_and_roles,
    get_retailer_users,
    get_retailer_roles,
    set_roles,
    reset_password,
    create_user,
    delete_user,
    update_roles,
    is_mfa_enforced,
    enforce_mfa,
    get_virtual_batteries,
    create_virtual_battery,
    delete_virtual_battery,
    update_virtual_battery,
    get_virtual_battery,
    get_battery_charge_schedule,
    clear_battery_charge_schedule,
    override_battery_charge_schedule,
    get_battery_forecast,
    get_battery_delivered,
    get_fcr_sessions_for_battery,
    get_fcr_session,
    get_fcr_session_log,
    get_active_announcements,
    get_manufacturers_and_models,
};
export default exportedObject;
