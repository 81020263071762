import React from 'react';
import Popup from 'reactjs-popup';
import { Button } from '../Common';
import cc from 'classcat';

const YesNoModal = ({ title, message, action, button, buttonClass }) => {
    return (
        <Popup trigger={<button className={cc(['button', buttonClass])}> {button} </button>} modal nested>
            {close => (
                <div>
                    <button className="modal__close" onClick={close}>
                        &#10005;
                    </button>
                    <div className="modal__header"> {title} </div>
                    <div className="modal__content">{message}</div>
                    <div className="modal__actions">
                        <Button
                            onClick={() => {
                                action();
                                close();
                            }}
                            text="Yes"
                        />
                        <Button
                            className="modal__cancel"
                            onClick={event => {
                                event.stopPropagation();
                                close();
                            }}
                            text="No"
                        />
                    </div>
                </div>
            )}
        </Popup>
    );
};

export default YesNoModal;
