import React from 'react';
import CustomerLookup from './CustomerLookup';

const SearchCustomers = () => {
    return (
        <>
            <CustomerLookup searchPageActive={true} />
        </>
    );
};

export default SearchCustomers;
