import { useEffect, useState } from 'react';
import api from '../api';

const useGetActiveCustomers = () => {
    const [isReady, setReady] = useState(false);
    const [data, setData] = useState([]);

    const getAllCustomers = async () => {
        try {
            const customers = await api.customer_numbers();

            let customerNumbers = [];
            customers.data.forEach(customer => {
                if (customer.device_count > 0) {
                    customerNumbers.push(customer.customer_id);
                }
            });

            setData(customerNumbers);
            setReady(true);
        } catch (error) {
            console.error('Error happened while loading customers. ', error);
        }
    };

    useEffect(() => {
        getAllCustomers();
    }, []);

    return { isReady, data };
};

export default useGetActiveCustomers;
