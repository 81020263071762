import React, { useEffect, useState } from 'react';
import { get_active_announcements } from '../../api';

const Announcements = () => {
    const [activeAnnouncements, setActiveAnnouncements] = useState();

    const getActive = async () => {
        const active = await get_active_announcements();
        setActiveAnnouncements(active.data);
    };

    useEffect(() => {
        getActive();
    }, []);

    return (
        <>
            {activeAnnouncements && (
                <div className="card-wrapper">
                    <h1 className="card-wrapper__title announcement__title">Announcements</h1>
                    {activeAnnouncements.length === 0 ? (
                        <h6 className="announcement__block">No active announcements</h6>
                    ) : (
                        <div>
                            {activeAnnouncements?.map(announcement => (
                                <div key={announcement.title} className="announcement__block">
                                    <h4>{announcement.title}</h4>
                                    <p className="announcement__description">{announcement.description}</p>
                                    {announcement.scope.map(scope => {
                                        if (scope) {
                                            return (
                                                <span key={scope} className="announcement__scope">
                                                    {scope.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
                                                </span>
                                            );
                                        }

                                        return <></>;
                                    })}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Announcements;
