import { useEffect, useState } from 'react';
import api from '../api';

const useGetAllFacilities = () => {
    const [isReady, setReady] = useState(false);
    const [data, setData] = useState([]);

    const getFacilities = async () => {
        try {
            const facilities = await api.places();
            setData(facilities.data);
            setReady(true);
        } catch (error) {
            console.error('Error happened while loading facilities. ', error);
        }
    };

    useEffect(() => {
        getFacilities();
    }, []);

    return { isReady, data };
};

export default useGetAllFacilities;
