import React, { useEffect, useState } from 'react';
import api from '../../api';
import { Button } from '../../components/Common';
import Loader from '../../components/Loader';

export function MFA({ realm, onEnabledSuccess, onEnabledFailure }) {
    let [enforced, setEnforced] = useState(undefined);
    let [working, setWorking] = useState(false);
    useEffect(() => {
        (async function () {
            setEnforced(await api.is_mfa_enforced(realm));
        })();
    }, [enforced]);

    async function enforceMFA() {
        setWorking(true);
        try {
            await api.enforce_mfa(realm);
            onEnabledSuccess();
            setEnforced(true);
        } catch (error) {
            onEnabledFailure();
            setWorking(false);
        }
    }

    if (enforced === undefined) {
        return (
            <div className="user-management__mfa-wrapper">
                <Loader className="user-management__loader" />
            </div>
        );
    } else if (enforced) {
        return (
            <div className="user-management__mfa-wrapper">
                <strong>Multi-factor authentication is enforced.</strong> Users will be required to configure an authenticator device to access the
                portal.
            </div>
        );
    } else {
        return (
            <div className="user-management__mfa-wrapper">
                <p>
                    Multi-factor authentication is currently <strong>not enforced</strong>. When enforced, portal users must use an authenticator
                    application (typically installed on their smartphone) to log in, entering a one-time code from the application in addition to
                    their password. This enhances security.
                </p>
                <p>
                    Before enabling MFA, <strong>we recommend that you inform all those who access the portal</strong>, so they are able to install an
                    authenticator app ahead of time, if they do not already have one. Options include:
                </p>
                <ul>
                    <li>
                        FreeOTP (<a href="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp">Android</a>,{' '}
                        <a href="https://apps.apple.com/us/app/freeotp-authenticator/id872559395">iPhone</a>)
                    </li>
                    <li>
                        Google Authenticator (
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Android</a>,{' '}
                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">iPhone</a>)
                    </li>
                </ul>
                <p>
                    <strong>Enforcing MFA cannot be reversed.</strong> Pressing the button below to enable it will require all portal users to
                    configure an authenticator app at their next login, and new users created in the future will be required to set it up during their
                    first login.
                </p>
                {!working && <Button text="I understand, enforce MFA now and forevermore" onClick={enforceMFA} />}
                {working && <strong>Enforcing MFA, wait a moment...</strong>}
            </div>
        );
    }
}
