import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';

export const DateRangeSelector = ({ fromValue, toValue, onFromChange, onToChange, granularity }) => {
    return (
        <>
            <span>From:</span>
            <DatePicker
                className="default-input reporting__filters--input"
                showPopperArrow={false}
                selected={fromValue}
                onChange={onFromChange}
                placeholderText="no date selected"
                isClearable={fromValue !== null}
                selectsStart
                startDate={fromValue}
                endDate={toValue}
                maxDate={toValue !== null ? toValue : new Date()}
                showMonthYearPicker={granularity === 'MONTH'}
            />
            <span>To:</span>
            <DatePicker
                className="default-input reporting__filters--input"
                showPopperArrow={false}
                selected={toValue}
                onChange={onToChange}
                placeholderText="no date selected"
                isClearable={toValue !== null}
                selectsEnd
                startDate={fromValue}
                endDate={toValue}
                minDate={fromValue !== null ? fromValue : undefined}
                maxDate={DateTime.now().toJSDate()}
                showMonthYearPicker={granularity === 'MONTH'}
            />
        </>
    );
};
