import React from 'react';
import SideNavbar from '../components/Navbar/SideNavbar';
import Navbar from '../components/Navbar/Navbar';

const Layout = props => {
    const year = new Date().getFullYear();

    return (
        <div className="layout">
            <Navbar traderName={props.traderName} logout={props.logout} />
            <div id="wrapper">
                <SideNavbar availableRoutes={props.availableRoutes} />
                <div className="layout__content-wrapper">
                    <div className="layout__content-wrapper--box">{props.children}</div>
                </div>
            </div>
            <footer className="footer">
                <div className="footer__container">
                    <span>&copy; 2021 - {year} All rights reserved.</span>
                </div>
            </footer>
        </div>
    );
};

export default Layout;
