import React from 'react';
import { GRANULARITIES } from '../RetailerReporting';
import { Select } from '../../../components/Common';

export const GranularitySelector = ({ value, onChange }) => {
    return (
        <>
            <span>Granularity:</span>
            <Select
                className="reporting__filters--input"
                value={value}
                onChange={onChange}
                options={GRANULARITIES.map(g => {
                    return { value: g.value, label: g.display };
                })}
            />
        </>
    );
};
