import { useEffect, useState } from 'react';
import { retailerReportingQuery } from '../api';

export const useGraphQLGetActiveDevices = (granularity, from, to) => {
    const [data, setData] = useState([]);
    const [isReady, setIsReady] = useState(true);
    const [distinctTypes, setDistinctTypes] = useState([]);

    const getActiveDevicesQuery = async (granularity, from, to) => {
        return await retailerReportingQuery(
            `query DeviceEventCount($granularity: Granularity!, $from: Date, $to: Date) {
                ACTIVE_DEVICES: activeDevices(granularity: $granularity, from: $from, to: $to) {
                    start,
                    count,
                    types {
                        ...typeBreakdown
                    }
                }
            }
            fragment typeBreakdown on DeviceTypeBreakdown {
                type
                count
            }`,
            { granularity, from, to }
        );
    };

    useEffect(() => {
        (async function () {
            setIsReady(true);
            const results = await getActiveDevicesQuery(granularity, from, to);

            const distinctTypes = new Set();
            const stats = results.ACTIVE_DEVICES.map(result => {
                const { start, count, types } = result;

                const typeCounts = types.reduce((acc, type) => {
                    acc[type.type] = type.count;
                    return acc;
                }, {});

                const allTypes = types.map(type => {
                    distinctTypes.add(type.type);
                    return type.type;
                });
                return { start, count, ...typeCounts, allTypes };
            });

            setData(stats);
            setDistinctTypes(Array.from(distinctTypes));
            setIsReady(false);
        })();
    }, []);

    return { isReady, data, distinctTypes };
};
