import React, { useEffect, useState } from 'react';
import { Input } from '..';
import cc from 'classcat';

export const Autocomplete = ({ searchValue, handleInput, handleSearchResult, searchResults }) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [activeSuggestion, setActiveSuggestion] = useState();

    const handleKeyDown = event => {
        if (event.key === 'ArrowUp') {
            if (activeSuggestion === 0) {
                setActiveSuggestion(searchResults.length - 1);
            }

            if (activeSuggestion > 0) {
                setActiveSuggestion(activeSuggestion - 1);
            }
        } else if (event.key === 'ArrowDown') {
            if (activeSuggestion === searchResults.length - 1) {
                setActiveSuggestion(0);
            }
            if (activeSuggestion < searchResults.length - 1) {
                setActiveSuggestion(activeSuggestion + 1);
            }
        } else if (event.key === 'Enter') {
            event.preventDefault();
            handleSearchResult(searchResults[activeSuggestion]);
        }
    };

    useEffect(() => {
        setActiveSuggestion(0);
        searchResults.length > 0 && setOpenMenu(true);
    }, [searchValue, searchResults]);

    return (
        <div className={cc(['autocomplete', openMenu && 'autocomplete--bubbled'])}>
            <Input
                className="autocomplete__input"
                type="text"
                placeholder="Enter customer number"
                name="search-customers"
                value={searchValue}
                onChange={e => handleInput(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            {openMenu && (
                <>
                    <div className={cc(['autocomplete__search-result-list'])}>
                        {searchResults?.map((item, index) => (
                            <li
                                className={cc([
                                    'autocomplete__search-result-list--item',
                                    activeSuggestion === index && 'autocomplete__search-result-list--item-active',
                                ])}
                                key={item}
                                id={item}
                                onClick={() => {
                                    handleSearchResult(item);
                                }}
                            >
                                {item}
                            </li>
                        ))}
                    </div>
                    <div className="autocomplete__event-bubbling-mask" onClick={() => setOpenMenu(false)}></div>
                </>
            )}
        </div>
    );
};
