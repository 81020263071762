import React, { useReducer } from 'react';
import cc from 'classcat';
import { toast } from 'react-toastify';
import { toastOptions } from './UserManagement';
import { Button } from '../../components/Common';

export const selectedRolesReducer = (roles, action) => {
    if (action.type === 'add') {
        return [...roles, action.selectedRole];
    }

    if (action.type === 'remove') {
        return roles.filter(r => r !== action.selectedRole);
    }

    if (action.type === 'reset') {
        return [];
    }

    throw Error('Unknown action happened while selecting roles!');
};

const SelectRolesForm = ({ allRoles, type, action, userRoles }) => {
    const [newUserRoleList, dispatch] = useReducer(selectedRolesReducer, userRoles);

    const handleSelectRoles = selectedRole => {
        if (newUserRoleList.includes(selectedRole)) {
            dispatch({ type: 'remove', selectedRole });
        } else {
            dispatch({ type: 'add', selectedRole });
        }
    };

    const validateAndUpdateRoles = () => {
        if (newUserRoleList.length === 0) {
            toast.error('The user must be granted at least one role.', { ...toastOptions, autoClose: 800 });
        } else {
            action(newUserRoleList);
        }
    };

    return (
        <>
            <div className={cc([type === 'update' && 'user-management__role-select-modal'])}>
                <div className="user-management__role-select">
                    {allRoles.map(role => (
                        <label key={role.label}>
                            <input
                                type="checkbox"
                                name={role.label}
                                checked={newUserRoleList.includes(role.label)}
                                onChange={() => handleSelectRoles(role.label)}
                            />
                            {role.description}
                        </label>
                    ))}
                </div>
            </div>
            {type === 'update' ? (
                <div className="actions">
                    <Button
                        text="Save"
                        onClick={() => {
                            validateAndUpdateRoles();
                        }}
                    />
                </div>
            ) : (
                <Button
                    text="Create User"
                    type="button"
                    onClick={event => {
                        event.preventDefault();
                        validateAndUpdateRoles();
                        dispatch({ type: 'remove' });
                    }}
                    className="user-management__create-user--button"
                />
            )}
        </>
    );
};

export default SelectRolesForm;
