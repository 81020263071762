import React from 'react';
import { granularityToUnitAndFormat } from '../../RetailerReporting';
import Chart from 'react-google-charts';

const ChargeSessions = ({ results, granularity, dates }) => {
    let [unit, format] = granularityToUnitAndFormat(granularity);
    let data = [
        [
            unit,
            'Successful',
            { role: 'tooltip', type: 'string' },
            'Charged After Deadline',
            { role: 'tooltip', type: 'string' },
            'Away',
            { role: 'tooltip', type: 'string' },
            'SPO Inactive',
            { role: 'tooltip', type: 'string' },
            'Currently Unclassified',
            { role: 'tooltip', type: 'string' },
        ],
    ];
    let hasNonZeroData = false;
    for (let datapoint of results.CHARGE_SESSIONS) {
        if (datapoint.count > 0) {
            hasNonZeroData = true;
        }

        let successful = 0,
            lost = 0,
            away = 0,
            inactive = 0,
            unknown = 0;
        let successfulTooltip = 'No Breakdown',
            lostTooltip = 'No Breakdown',
            awayTooltip = 'No Breakdown',
            inactiveTooltip = 'No Breakdown',
            unknownTooltip = 'No Breakdown';

        for (let status of datapoint.status) {
            switch (status.status) {
                case 'SUCCESSFUL':
                    successful += status.count;
                    successfulTooltip = `${successful} successful charging sessions`;
                    break;
                case 'LOST':
                    lost += status.count;
                    lostTooltip = `${lost} charged after deadline`;
                    break;
                case 'AWAY':
                    away += status.count;
                    awayTooltip = `${away} charging sessions away from home`;
                    break;
                case 'SPO_DISABLED':
                    inactive += status.count;
                    inactiveTooltip = `${inactive} SPO inactive charging sessions`;
                    break;
                case 'CURRENTLY_UNKNOWN':
                    unknown += status.count;
                    unknownTooltip = `${unknown} unclassified charging sessions`;
                    break;
                default:
                // Something we don't handle yet
            }
        }
        data.push([
            new Date(datapoint.start),
            successful,
            successfulTooltip,
            lost,
            lostTooltip,
            away,
            awayTooltip,
            inactive,
            inactiveTooltip,
            unknown,
            unknownTooltip,
        ]);
    }

    const options = {
        isStacked: true,
        hAxis: {
            format,
            slantedText: false,
            textStyle: {
                fontSize: 14,
            },
            ...(dates.length > 0 && { ticks: dates }),
        },
        vAxis: {
            format: '0',
            ...(hasNonZeroData ? {} : { viewWindow: { min: 0, max: 1 } }),
        },
        chartArea: {
            height: '80%',
        },
    };
    return (
        <div className="reporting__graph-wrapper--chart">
            <h3 className="reporting__title">Charge Sessions</h3>
            {results ? <Chart chartType="ColumnChart" width="100%" height="400px" data={data} options={options} /> : <p>Loading...</p>}
            <p className="reporting__charge-sessions-explanation">
                * Tracking of <b>"charged after deadline"</b> sessions began on July 25th, 2023. Charge sessions after this date will include this
                session classification. Charge sessions from before this date are labeled as "currently unclassified" and may be retroactively
                reconstructed (data-permitting) in future updates to the platform.
            </p>
        </div>
    );
};

export default ChargeSessions;
