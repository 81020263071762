import React from 'react';
import Popup from 'reactjs-popup';
import { useEffect } from 'react';
import { useState } from 'react';
import logsFunc from '../../components/DeviceHistoryLogs/logs';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const DevicesPageModal = props => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    const history = useHistory();

    const device_id = props.device?.device_id || props.device?.public_id;
    const customer_id = props.device?.customer || props.device?.retailer_customer_id;

    useEffect(() => {
        setLoading(true);
        if (props.device !== undefined) {
            let from = new Date(new Date().setDate(new Date().getDate() - 2)).toISOString();
            let logs = [];
            logsFunc.getLogsForDevice(from, new Date().toISOString(), device_id).then(res => {
                logs = res;
                setLogs(logs);

                setTimeout(() => {
                    setLoading(false);
                }, 400);
            });
        }
    }, [props.device]);

    return (
        <Popup open={props.open} closeOnDocumentClick onClose={() => props.setOpen(false)} className="modal">
            {() => (
                <div>
                    <button className="modal__close" onClick={() => props.setOpen(false)}>
                        &#10005;
                    </button>
                    <div className="modal__header"> Device History Logs</div>
                    <div className="modal__content">
                        <button className="devices-page-modal__link" onClick={() => history.push(`/devicesdetail/${customer_id}`)}>
                            CUSTOMER: <span className="devices-page-modal__link--customer">{customer_id}</span>
                        </button>
                        <span>DEVICE: {device_id}</span>
                        <hr />
                        {loading ? (
                            <Loader
                                type="Circles"
                                height="80"
                                width="80"
                                color="#E54F20"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        ) : logs.length === 0 || logs.filter(log => log.hide).length === logs.length ? (
                            'No logs available in the last 48h'
                        ) : (
                            <ul className="devices-page-modal__logs-container">
                                {logs
                                    ?.filter(log => !log.hide)
                                    .map(item => (
                                        <li key={item.timestamp}>
                                            <div className="customer-view__log-header">
                                                <p className="customer-view__log-timestamp">{item.timestamp}</p>
                                                <p className="customer-view__log-owner">{item?.source || 'system'}</p>
                                            </div>

                                            <p className="customer-view__event">{item.event}</p>
                                            {item.change && (
                                                <ul className="customer-view__change-list">
                                                    {item.change?.map(i => (
                                                        <li className="customer-view__sublist" key={i}>
                                                            <p>{i}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    ))}
                            </ul>
                        )}
                    </div>
                </div>
            )}
        </Popup>
    );
};

export default DevicesPageModal;
