import React from 'react';
import cc from 'classcat';

export const Select = ({ options, onChange, value, defaultValue, name, className, fullWidth }) => {
    return (
        <select
            name={name}
            className={cc(['select', className, fullWidth && 'select__full-width'])}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
        >
            {options.map(option => (
                <option key={option.value} value={option.value} hidden={option.hidden}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};
