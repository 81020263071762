import React, { useEffect, useState } from 'react';
import cc from 'classcat';
import api from '../../../api';
import { deviceTypeMap } from '../../../utils/constants';
import Loader from '../../../components/Loader';
import { showComment } from '../../RetailerAPI/SelectBrands';
import InfoTooltip from '../../../components/InfoTooltip/InfoTooltip';
import { Input } from '../../../components/Common';
import Pagination from '../../../components/Pagination';
import { useDebounce } from '../../../hooks/useDebounce';

const APICompatibility = () => {
    const [manufacturers, setManufacturers] = useState();
    const [loading, setLoading] = useState(true);
    const [expand, setExpanded] = useState({});

    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const pagingAndSortingDefaults = {
        sortBy: 'type',
        sortDirection: 'asc',
        offset: 0,
        limit: 10,
        currentPage: 1,
    };
    const [{ sortBy, sortDirection, offset, limit, currentPage }, setPagingAndSorting] = useState(pagingAndSortingDefaults);

    const getManufacturers = async () => {
        const filter = search || undefined;
        const response = await api.get_manufacturers_and_models(limit, offset, sortBy, sortDirection, filter);
        if (response && response.data) {
            setTotalCount(response.data.total);
            setManufacturers(response.data.manufacturers);
            setLoading(false);
        }
    };

    useEffect(() => {
        getManufacturers();
    }, [sortBy, sortDirection, offset, limit]);

    const debouncedSearch = useDebounce(() => {
        setPagingAndSorting(prev => ({ ...prev, offset: 0, currentPage: 1 }));
        getManufacturers();
    });

    const handleSearch = value => {
        setSearch(value || undefined);
        debouncedSearch();
    };

    const handleSort = column => {
        if (column === sortBy) {
            setPagingAndSorting(prev => ({ ...prev, sortDirection: sortDirection === 'asc' ? 'desc' : 'asc' }));
        } else {
            setPagingAndSorting(prev => ({ ...prev, sortBy: column, sortDirection: 'asc' }));
        }
    };

    const setPage = currentPage => {
        const offset = currentPage * limit - limit;
        setPagingAndSorting(prev => ({ ...prev, currentPage, offset }));
    };

    const toggleExpand = brand => {
        setExpanded(prevState => ({
            ...prevState,
            [brand]: !prevState[brand],
        }));
    };

    return (
        <div className="api-compatibility">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="api-compatibility__table-wrapper">
                        <div className="api-compatibility__search-wrapper">
                            <h3 className="card-wrapper__title api-compatibility__title">Device Compatibility</h3>
                            <Input
                                className="api-compatibility__search"
                                type="text"
                                placeholder="Search Manufacturers"
                                value={search}
                                onChange={e => handleSearch(e.target.value)}
                            />
                        </div>
                        <table className="devices-table__data">
                            <thead className="devices-table__data--header">
                                <tr>
                                    <th align="center" onClick={() => handleSort('label')}>
                                        <span className="devices-table__data--header-cell">
                                            <i className="fa-solid fa-sort"></i>
                                            Manufacturer
                                        </span>
                                    </th>
                                    <th align="center" onClick={() => handleSort('type')}>
                                        <span className="devices-table__data--header-cell">
                                            <i className="fa-solid fa-sort"></i>
                                            Device Type
                                        </span>
                                    </th>
                                    <th align="center">Supported Models</th>
                                </tr>
                            </thead>
                            <tbody>
                                {manufacturers.length === 0 ? (
                                    <tr className="api-compatibility__not-found">
                                        <td colSpan={3}>No Manufacturers or Models Found</td>
                                    </tr>
                                ) : (
                                    manufacturers?.map((manufacturer, i) => {
                                        return (
                                            <React.Fragment key={`${manufacturer.id}_${i}`}>
                                                <tr className="devices-table__data--row">
                                                    <td align="center">
                                                        <div className="api-compatibility__label">
                                                            {manufacturer.label}
                                                            {manufacturer.state !== 'stable' && manufacturer.comment && (
                                                                <InfoTooltip tooltip={showComment(manufacturer)} />
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td align="center">
                                                        <div
                                                            className={cc([
                                                                'api-compatibility__device-type',
                                                                `api-compatibility__device-type--${manufacturer.type.toLowerCase()}`,
                                                            ])}
                                                        >
                                                            {deviceTypeMap[manufacturer.type.toLowerCase()] ?? manufacturer.type}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {manufacturer.models.length > 0 ? (
                                                            <button onClick={() => toggleExpand(manufacturer.id)}>
                                                                {expand[manufacturer.id] ? (
                                                                    <i className="fa-solid fa-sort-down api-compatibility__models--open"></i>
                                                                ) : (
                                                                    <i className="fa-solid fa-sort-down"></i>
                                                                )}
                                                            </button>
                                                        ) : (
                                                            'No models available'
                                                        )}
                                                    </td>
                                                </tr>
                                                {expand[manufacturer.id] && (
                                                    <tr>
                                                        <td
                                                            colSpan={3}
                                                            align="end"
                                                            className={cc([
                                                                `${
                                                                    expand[manufacturer.id]
                                                                        ? 'api-compatibility__models--expanded'
                                                                        : 'api-compatibility__models--collapsed'
                                                                }`,
                                                            ])}
                                                        >
                                                            <span className="api-compatibility__models">
                                                                {manufacturer.models
                                                                    .filter(
                                                                        m => m.capabilities.reliability.replace(/\s/g, '').toLowerCase() !== 'n/a'
                                                                    )
                                                                    .map(model => (
                                                                        <span
                                                                            key={`${manufacturer.id}_${model.model}`}
                                                                            className={cc(['api-compatibility__models--model'])}
                                                                        >
                                                                            {model.model}
                                                                        </span>
                                                                    ))}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                    {totalCount !== 0 && (
                        <Pagination elementsPerPage={limit} totalElements={totalCount} currentPage={currentPage} setCurrentPage={setPage} />
                    )}
                </>
            )}
        </div>
    );
};

export default APICompatibility;
