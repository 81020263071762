import { DateTime } from 'luxon';
import { useState } from 'react';

const useEndDateValidation = () => {
    const [to, setTo] = useState(null);

    const validateAndSetDate = (date, granularity) => {
        if (granularity === 'MONTH' && date) {
            const end = DateTime.fromJSDate(date).endOf('month').toJSDate();
            setTo(end);
        } else {
            setTo(date);
        }
    };

    return [to, validateAndSetDate];
};

export default useEndDateValidation;
