import React, { useState } from 'react';
import cc from 'classcat';
import api from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import CreateFacilityModal from '../../components/Modal/CreateFacilityModal';
import { useDebounce } from '../../hooks/useDebounce';
import { Autocomplete, Button, Select } from '../../components/Common';
import { useHistory } from 'react-router';

const options = {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const criteriaOptions = [
    { value: 'customer_id', label: 'Customer Number' },
    { value: 'facility_id', label: 'Facility ID' },
    { value: 'address', label: 'Address' },
];

const CustomerLookup = ({ searchPageActive }) => {
    const [customerList, setCustomerList] = useState([]);
    const [querySearchResults, setQuerySearchResults] = useState([]);
    const [searchCustomersId, setSearchCustomersId] = useState('');
    const [searchCriteria, setSearchCriteria] = useState(criteriaOptions[0].value);
    const [customersWithDevices, setCustomersWithDevices] = useState(true);
    const [notFoundSearchError, setNotFoundSearchError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();

    const loadCustomers = async () => {
        if (!searchCustomersId) {
            return;
        }

        let filter = !customersWithDevices;
        if (!searchPageActive) {
            filter = true;
        }

        const customerListRawRes = await api.customer_numbers(filter, searchCriteria, searchCustomersId);
        if (!customerListRawRes) {
            toast.error('No customers found, please try again!', options);
        }

        const customerList = customerListRawRes.data;
        if (!customerList || !Array.isArray(customerList) || !customerList.length) {
            setNotFoundSearchError(true);
        }

        setCustomerList(customerList ?? undefined);
        return customerList;
    };

    const selectSearchCriteria = criteria => {
        setSearchCustomersId('');
        setQuerySearchResults([]);
        setSearchCriteria(criteria);
    };

    const filterCustomers = () => {
        setCustomersWithDevices(prev => !prev);
    };

    const handleSearchResult = criteria => {
        if (querySearchResults.length === 0) {
            setNotFoundSearchError(true);
        } else {
            if (searchCriteria === 'customer_id') {
                let customer = customerList.filter(c => c.customer_id === criteria);
                history.push(`/devicesdetail/${customer[0].customer_id}`);
            } else if (searchCriteria === 'facility_id') {
                let customer = customerList.filter(c => c.facility_id_list.includes(criteria));
                history.push(`/devicesdetail/${customer[0].customer_id}`);
            } else if (searchCriteria === 'address') {
                let customer = customerList.filter(c => c.address_list.includes(criteria));
                history.push(`/devicesdetail/${customer[0].customer_id}`);
            }
        }

        setSearchCustomersId('');
        setQuerySearchResults([]);
    };

    const handleSearch = async () => {
        let customers = await loadCustomers();
        if (!customers) {
            return;
        }

        let searchResults = [];
        if (searchCriteria === 'customer_id') {
            searchResults = customers.map(item => item.customer_id);
        } else if (searchCriteria === 'facility_id') {
            let facilities = customers.map(item => {
                return item.facility_id_list.filter(f => f.toLowerCase().includes(searchCustomersId.toLowerCase()))[0];
            });

            searchResults.push(...facilities);
        } else {
            let addresses = customers.map(item => {
                return item.address_list.filter(a => a.toLowerCase().includes(searchCustomersId.toLowerCase()))[0];
            });

            searchResults.push(...addresses);
        }

        if (customers.length === 0) {
            setNotFoundSearchError(true);
        } else {
            setNotFoundSearchError(false);
        }

        setQuerySearchResults(searchResults);
    };

    const debouncedRequest = useDebounce(() => {
        handleSearch();
    });

    const handleSearchCustomersInput = text => {
        setSearchCustomersId(text);
        setNotFoundSearchError(false);

        if (text === '') {
            setQuerySearchResults([]);
            return;
        }

        debouncedRequest();
    };

    return (
        <div className={cc(['customer-lookup', searchPageActive && 'customer-lookup__search-page'])}>
            <ToastContainer />
            {searchPageActive ? (
                <div className="customer-lookup__wrapper">
                    <div className="customer-lookup__search-box">
                        <h2 className="customer-lookup__title">Customer Lookup</h2>
                        <div className="customer-lookup__search-box--form">
                            <Select
                                name="criteria"
                                onChange={e => selectSearchCriteria(e.target.value)}
                                options={criteriaOptions}
                                defaultValue={'customer_id'}
                                className="customer-lookup__criteria"
                            />
                            <Autocomplete
                                searchValue={searchCustomersId}
                                handleInput={handleSearchCustomersInput}
                                handleSearchResult={handleSearchResult}
                                searchResults={querySearchResults}
                            />
                            {notFoundSearchError && <span className="customer-lookup__search-error-message">No customers found</span>}
                            <div className="customer-lookup__filter">
                                <input
                                    name="filter-customers"
                                    id="filter"
                                    type="checkbox"
                                    onChange={filterCustomers}
                                    checked={customersWithDevices}
                                />
                                <label htmlFor="filter">Only show customers with onboarded devices</label>
                            </div>
                            <Button
                                text="Search"
                                className="customer-lookup__button"
                                onClick={() => {
                                    handleSearchResult(searchCustomersId);
                                }}
                                disabled={notFoundSearchError}
                            />
                        </div>
                    </div>
                    <div className="customer-lookup__actions">
                        <button className="customer-lookup__open-modal-link" onClick={() => setOpenModal(!openModal)}>
                            <i className="fa-solid fa-square-plus add-btn"></i>
                            Manually Add Facility
                        </button>
                        <CreateFacilityModal open={openModal} setOpen={setOpenModal} loadCustomers={loadCustomers} />
                    </div>
                </div>
            ) : (
                <Autocomplete
                    searchValue={searchCustomersId}
                    handleInput={handleSearchCustomersInput}
                    handleSearchResult={handleSearchResult}
                    searchResults={querySearchResults}
                />
            )}
        </div>
    );
};

export default CustomerLookup;
