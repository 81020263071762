import React, { useEffect, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import { retailerReportingQuery } from '../../../api';

import { BREAKDOWNS, GRANULARITIES, sortByCount, xAxisLabels } from '../RetailerReporting';
import useDateValidation from '../useEndDateValidation';

import DeviceEventSection from './DeviceEventSection';
import { BreakdownSelector, DateRangeSelector, GranularitySelector } from '../Selectors';
import ChargeSessions from './Graphs/ChargeSessions';
import { SavingsHistogram } from './Graphs';

export function manufacturerOrModelBreakdownTooltip(breakdown, customer) {
    let sortedBreakdown = sortByCount(breakdown);
    let maxCount = sortedBreakdown[0]?.count || 1;
    return ReactDOMServer.renderToString(
        <div style={{ padding: '5px' }}>
            {breakdown.map(entry => {
                let pc = 100 * (entry.count / maxCount);
                return (
                    <div
                        key={entry.name}
                        style={{
                            background: `linear-gradient(.25turn, #f4c28d ${pc}%, #f7e8d8)`,
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'end',
                            minWidth: entry.customer || customer ? '480px' : '300px',
                        }}
                    >
                        <strong>{entry.name}</strong>
                        <span>{entry.count}</span>
                        {entry.customer ? (
                            <span>
                                <strong>Customer: &nbsp;&nbsp;&nbsp;</strong>
                                {entry.customer}
                            </span>
                        ) : (
                            customer && <span>Customer: {customer}</span>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

const Devices = () => {
    const EVENTS = [
        {
            event: 'ACTIVE_DEVICES',
            display: 'Total active devices',
        },
        {
            event: 'ONBOARDING_SUCCESS',
            display: 'Successful onboardings',
            note: 'includes re-onboardings',
        },
        {
            event: 'ONBOARDING_FAILURE',
            display: 'Unsuccessful onboardings',
        },
        {
            event: 'LOST',
            display: 'Lost devices',
        },
        {
            event: 'DELETED',
            display: 'Removed devices',
        },
        {
            event: 'COMMAND_ERROR',
            display: 'Errors sending commands',
        },
    ];
    let [granularity, setGranularity] = useState(GRANULARITIES[2].value);
    let [from, setFrom] = useState(null);
    const [to, validateAndSetDate] = useDateValidation();
    let [breakdown, setBreakdown] = useState(BREAKDOWNS[0].value);
    let [queryResults, setQueryResults] = useState({});

    useEffect(() => {
        (async function () {
            setQueryResults(
                await retailerReportingQuery(
                    `query DeviceEventCount($granularity: Granularity!, $from: Date, $to: Date) {
                  ACTIVE_DEVICES: activeDevices(granularity: $granularity, from: $from, to: $to) {
                    start,
                    count,
                    types {
                      ...typeBreakdown
                    }
                  },
                  ONBOARDING_SUCCESS: deviceEventCounts(event: ONBOARDING_SUCCESS, granularity: $granularity, from: $from, to: $to) {
                    ...breakdown
                  }
                  ONBOARDING_FAILURE: deviceEventCounts(event: ONBOARDING_FAILURE, granularity: $granularity, from: $from, to: $to) {
                    ...breakdown
                  }
                  LOST: deviceEventCounts(event: LOST, granularity: $granularity, from: $from, to: $to) {
                    ...breakdown
                  }
                  DELETED: deviceEventCounts(event: DELETED, granularity: $granularity, from: $from, to: $to) {
                    ...breakdown
                  }
                  COMMAND_ERROR: deviceEventCounts(event: COMMAND_ERROR, granularity: $granularity, from: $from, to: $to) {
                    start,
                    count,
                    types {
                      ...typeBreakdown
                    }
                  }
                  CHARGE_SESSIONS: chargeSessions(granularity: $granularity, from: $from, to: $to) {
                    ...chargeSessionsBreakdown
                  },
                }
                fragment typeBreakdown on DeviceTypeBreakdown {
                  type,
                  count,
                  manufacturers {
                    name,
                    count,
                    models {
                      name,
                      count
                     }
                  }
                }
                fragment breakdown on DeviceEventCount {
                  start,
                  count,
                  types {
                    type,
                    count,
                    manufacturers {
                      name,
                      count,
                      customer
                      models {
                        name,
                        count
                       }
                    }
                  }
                }
                fragment chargeSessionsBreakdown on ChargeSession {
                  start,
                  count,
                  status {
                    status,
                    count
                  }
                }
                `,
                    { granularity, from, to }
                )
            );
        })();
    }, [granularity, from, to]);

    const dates = useMemo(() => {
        return xAxisLabels(queryResults[EVENTS[0].event]);
    }, [queryResults]);

    return (
        <>
            <div className="reporting__filters">
                <GranularitySelector
                    value={granularity}
                    onChange={e => {
                        setQueryResults({});
                        setGranularity(e.target.value);
                    }}
                />
                <DateRangeSelector
                    fromValue={from}
                    toValue={to}
                    onFromChange={date => setFrom(date)}
                    onToChange={date => validateAndSetDate(date, granularity)}
                    granularity={granularity}
                />
                <BreakdownSelector
                    value={breakdown}
                    onChange={e => {
                        setBreakdown(e.target.value);
                    }}
                />
            </div>

            <div className="reporting__graph-wrapper">
                {EVENTS.map(e => (
                    <DeviceEventSection
                        key={e.event}
                        metadata={e}
                        results={queryResults[e.event]}
                        breakdown={breakdown}
                        granularity={granularity}
                        dates={dates}
                    />
                ))}
                {queryResults['CHARGE_SESSIONS'] && <ChargeSessions results={queryResults} granularity={granularity} dates={dates} />}
                <SavingsHistogram granularity={granularity} from={from} to={to} />
            </div>
        </>
    );
};

export default Devices;
