import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import KeyManagement from './KeyManagement';
import SelectBrand from './SelectBrands';
import CustomerWebView from './CustomerWebView.js';

const tableContent = [
    {
        title: 'Web View',
        approach:
            'Allow your customers to sign up their devices and get feedback on their optimization using an Emulate-provided web UI (which can also be embedded as a web view in a mobile application). The only backend work is to sign a JWT to securely identify your customer to Emulate.',
        difficulty: 'Easy',
        difficultyLevel: 1,
        accessKey: 'Single Customer',
    },
    {
        title: 'Single Customer API',
        approach:
            'Get programmatic access to all that the Emulate web UI offers, to build your own custom user experience, either on mobile or web. This API provides access to the information of a single customer at a time, identified by a signed JWT, and so needs minimal backend work (the JWT can then be passed to a mobile application or frontend web application, which can then consume the API).',
        difficulty: 'Intermediate',
        difficultyLevel: 2,
        accessKey: 'Single Customer',
    },
    {
        title: 'Retailer API',
        approach:
            "Get access to information about all customers of the retailer, the rate of device signups, insights into customer needs, and more. This approach is only suitable for calling from a backend application, and it is on you to make sure one customer's data doesn't reach another.",
        difficulty: 'Advanced',
        difficultyLevel: 3,
        accessKey: 'Retailer',
    },
];

const RetailerAPI = () => {
    return (
        <Tabs className="tabs">
            <TabList className="tabs__tab-nav">
                <Tab>Overview</Tab>
                <Tab>Key Management</Tab>
                <Tab>Customer WebView</Tab>
                <Tab>Select Manufacturers</Tab>
            </TabList>
            <TabPanel>
                <Overview />
            </TabPanel>
            <TabPanel>
                <KeyManagement />
            </TabPanel>
            <TabPanel>
                <CustomerWebView retailerId={window.token_parsed?.trader_id || 1} />
            </TabPanel>
            <TabPanel>
                <SelectBrand />
            </TabPanel>
        </Tabs>
    );
};

const Overview = () => {
    return (
        <div className="retailer-api">
            <h1 className="retailer-api__title">API Overview</h1>
            <p className="retailer-api__description">Emulate provides three ways for retailers to integrate.</p>
            <table className="table">
                <thead>
                    <tr>
                        <th>Approach</th>
                        <th>Difficulty</th>
                        <th>Access Key Needed</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent.map((content, i) => (
                        <tr key={i}>
                            <td>
                                <strong>{content.title}</strong>
                                <p>{content.approach}</p>
                            </td>
                            <td align="center" className="retailer-api__difficulty">
                                <div className="retailer-api__difficulty--level">
                                    {Array(content.difficultyLevel)
                                        .fill(i)
                                        .map((_, i) => (
                                            <span key={i} />
                                        ))}
                                </div>
                                {content.difficulty}
                            </td>
                            <td align="center">{content.accessKey}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RetailerAPI;
