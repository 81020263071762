import React from 'react';
import Popup from 'reactjs-popup';
import api from '../../api';
import { useState } from 'react';
import { Select, Button } from '../Common';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router';

const FacilityDeletionModal = ({ facilities }) => {
    const [selectedFacility, setSelectedFacility] = useState(facilities[0].facility_id);
    const [showLoader, setShowLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const history = useHistory();

    const facilityOptions = facilities.map(f => {
        return { value: f.facility_id, label: f.retailer_facility_id };
    });

    function selectFacility(e) {
        setSelectedFacility(e.target.value);
    }

    async function deleteFacility(close) {
        if (selectFacility) {
            setShowLoader(true);

            try {
                await api.delete_facility(selectedFacility);

                if (facilities.length === 1) {
                    history.push('/devicesdetail');
                } else {
                    history.push(`/devicesdetail/${facilities[0].retailer_customer_id}`);
                }

                setShowLoader(false);
                close();
            } catch (e) {
                setErrorMessage('An error happened while trying to delete a facility');
            }
        }
    }

    return (
        <Popup trigger={<button className="button"> Delete Facility </button>} modal nested className="modal">
            {close => (
                <div>
                    <button className="modal__close" onClick={close}>
                        &#10005;
                    </button>
                    <div className="modal__header"> Delete facility </div>
                    <div className="modal__content">
                        Removing a facility will also remove all devices associated with it. Are you sure you want to proceed?
                        <Select defaultValue="" onChange={e => selectFacility(e)} options={facilityOptions} />
                        {errorMessage && <span className="modal__error-message">{errorMessage}</span>}
                    </div>
                    <div className="modal__actions">
                        <Button
                            text={
                                <div className="modal__spinner-button">
                                    Delete
                                    {showLoader && (
                                        <Loader
                                            type="Circles"
                                            height="30"
                                            width="30"
                                            color="#fff"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    )}
                                </div>
                            }
                            onClick={() => {
                                deleteFacility(close);
                            }}
                        />
                        <Button
                            className="modal__cancel"
                            text="Cancel"
                            onClick={() => {
                                close();
                            }}
                        />
                    </div>
                </div>
            )}
        </Popup>
    );
};

export default FacilityDeletionModal;
