import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_REPORT_API_URL,
});

export async function reportMetric(message, metric, extra = {}) {
    await instance.post(
        '/metric',
        JSON.stringify({
            service: 'dashboard',
            message,
            metric,
            ...extra,
        }),
        {
            headers: {
                'content-type': 'application/json',
            },
        }
    );
}

async function reportError(message, stack = []) {
    try {
        await instance.post(
            '/error',
            JSON.stringify({
                service: 'dashboard',
                message,
                stack,
            }),
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        );
    } catch (error) {
        console.log(error);
    }
}

export function setupErrorReporting() {
    window.addEventListener('error', function (e) {
        let error = e.error;
        if (e.error) {
            let message = e.error.message || String(error);
            let stack = e.error.stack || '';
            reportError(message, stack.split('\n'));
        }
    });
    window.addEventListener('unhandledrejection', function (e) {
        let error = e.reason;
        if (error) {
            let message = error.message || String(error);
            let stack = error.stack || '';
            reportError(message, stack.split('\n'));
        }
    });
}
