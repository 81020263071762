import { useEffect, useState } from 'react';
import api from '../../api';
import AddUserForm from './AddUserForm';
import UsersTable from './UsersTable';

import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { MFA } from './MFA';

export const toastOptions = {
    position: 'top-center',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
};

const UserManagement = () => {
    // Version of data is used to ensure refresh upon additions, deletions, etc.
    let [roles, setRoles] = useState(undefined);
    let [users, setUsers] = useState();

    const getRoles = async () => {
        setRoles((await api.get_retailer_roles()).data);
    };

    const getUsers = async () => {
        setUsers((await api.get_retailer_users()).data);
    };

    useEffect(() => {
        getRoles();
        getUsers();
    }, []);

    const message = response => {
        return (
            <div className="toast-message">
                <p>The password is changed.</p>
                <p>New credentials are:</p>
                <p>
                    <b>Email:</b> {response.data.email}
                </p>
                <p>
                    <b>Password:</b> {response.data.password}
                </p>
                <p>Note the temporary password must be changed.</p>
            </div>
        );
    };

    async function handlePasswordReset(email) {
        let response = await api.reset_password(email);
        toast.info(message(response), toastOptions);
    }

    const deleteUser = async email => {
        await api.delete_user(email);
        getUsers();
        toast.success('Successfully deleted user!', { ...toastOptions, autoClose: 800 });
    };

    if (!roles) {
        return <p>Loading...</p>;
    }
    return (
        <>
            <ToastContainer className="user-management__alert-container" style={{ width: '40%' }} />
            <div className="user-management">
                <h2>Manage Users</h2>
                <h3 className="reporting__title">Add User</h3>
                <AddUserForm roles={roles} message={message} getUsers={getUsers} />
                <h3 className="reporting__title">Current Users</h3>
                <UsersTable
                    users={users}
                    getUsers={getUsers}
                    onDeletion={email => deleteUser(email)}
                    onPasswordReset={email => handlePasswordReset(email)}
                    roles={roles}
                />
                <h3 className="reporting__title">MFA</h3>
                <MFA
                    realm="retailer"
                    onEnabledSuccess={() => toast.success('Successfully enabled MFA', toastOptions)}
                    onEnabledFailure={() => toast.error('Failed to enable MFA', toastOptions)}
                />
            </div>
        </>
    );
};

export default UserManagement;
