import React, { useState } from 'react';
import ChangeRolesModal from '../../components/Modal/ChangeRolesModal';
import YesNoModal from '../../components/Modal/YesNoModal';
import { Button } from '../../components/Common';

const UsersTable = ({ users, getUsers, onDeletion, onPasswordReset, roles }) => {
    const [selectedUser, setSelectedUser] = useState();
    const [open, setOpen] = useState(false);

    if (!users) {
        return <p>Loading...</p>;
    }

    return (
        <div className="user-management__user-table">
            <table className="table">
                <colgroup>
                    <col width="20%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="30%" />
                    <col width="60%" />
                </colgroup>
                <thead>
                    <tr>
                        <th>Login</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Roles</th>
                        <th>Operations</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(u => (
                        <tr key={u.email}>
                            <td>{u.email}</td>
                            <td>{u.firstname}</td>
                            <td>{u.lastname}</td>
                            <td>
                                <ul className="user-management__user-table--list">
                                    {u.roles.map(label => {
                                        const foundRole = roles.find(role => role.label === label);
                                        return <li key={foundRole.label}>{foundRole.description || foundRole.label}</li>;
                                    })}
                                </ul>
                            </td>
                            <td>
                                <div className="user-management__user-table--operations">
                                    <Button
                                        text="Change Roles"
                                        onClick={() => {
                                            setSelectedUser(u);
                                            setOpen(true);
                                        }}
                                    />
                                    <ChangeRolesModal
                                        email={selectedUser?.email}
                                        allRoles={roles}
                                        userRoles={selectedUser?.roles}
                                        getUsers={getUsers}
                                        open={open}
                                        setOpen={setOpen}
                                    />
                                    <YesNoModal
                                        title={`Reset password for ${u.email}`}
                                        message={'Are you sure you want to reset password for this user?'}
                                        action={() => onPasswordReset(u.email)}
                                        button={'Reset password'}
                                    />
                                    <YesNoModal
                                        title={`Delete user`}
                                        message={`Are you sure you want to delete user ${u.email}?`}
                                        action={() => onDeletion(u.email)}
                                        button={'Delete'}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UsersTable;
