import React from 'react';
import { granularityToUnitAndFormat } from '../../RetailerReporting';
import { manufacturerOrModelBreakdownTooltip } from '../Devices';
import Chart from 'react-google-charts';

export const DeviceEventGraphByType = ({ results, granularity, dates }) => {
    // Transform data.
    let [unit, format] = granularityToUnitAndFormat(granularity);
    let data = [
        [
            unit,
            'HVAC',
            { role: 'tooltip', type: 'string', p: { html: true } },
            'EV',
            { role: 'tooltip', type: 'string', p: { html: true } },
            'CS',
            { role: 'tooltip', type: 'string', p: { html: true } },
        ],
    ];
    let hasNonZeroData = false;
    for (let datapoint of results) {
        if (datapoint.count > 0) {
            hasNonZeroData = true;
        }
        let hvac = 0,
            ev = 0,
            cs = 0;
        let hvacTooltip = 'No Breakdown',
            evTooltip = 'No Breakdown',
            csTooltip = 'No Breakdown';
        for (let type of datapoint.types) {
            switch (type.type) {
                case 'HVAC':
                    hvac += type.count;
                    hvacTooltip = manufacturerOrModelBreakdownTooltip(type.manufacturers);
                    break;
                case 'EV':
                    ev += type.count;
                    evTooltip = manufacturerOrModelBreakdownTooltip(type.manufacturers);
                    break;
                case 'CS':
                    cs += type.count;
                    csTooltip = manufacturerOrModelBreakdownTooltip(type.manufacturers);
                    break;
                default:
                // Something we don't handle yet
            }
        }
        data.push([new Date(datapoint.start), hvac, hvacTooltip, ev, evTooltip, cs, csTooltip]);
    }

    // Display stacked bar chart.
    const options = {
        isStacked: true,
        hAxis: {
            format,
            slantedText: false,
            textStyle: {
                fontSize: 14,
            },
            ...(dates.length > 0 && { ticks: dates }),
        },
        vAxis: {
            format: '0',
            ...(hasNonZeroData ? {} : { viewWindow: { min: 0, max: 1 } }),
        },
        tooltip: {
            isHtml: true,
            trigger: 'selection',
        },
        chartArea: {
            height: '80%',
        },
    };
    return <Chart chartType="ColumnChart" width="100%" height="400px" data={data} options={options} />;
};
