import React, { useState, useEffect, Fragment } from 'react';
import Popup from 'reactjs-popup';

import { Button } from '../../components/Common';
import { get_battery_charge_schedule, clear_battery_charge_schedule } from '../../api';
import { DateTime } from 'luxon';

const ChargeScheduleVisualizer = ({ batteryId, canManageBatteries }) => {
    const [chargeSchedule, setChargeSchedule] = useState([]);
    const [confirmClearModalOpen, setConfirmClearModalOpen] = useState(false);
    const [period, setPeriod] = useState(15);

    const current_period_start = new Date();
    current_period_start.setMinutes(current_period_start.getMinutes() - (current_period_start.getMinutes() % 15), 0, 0);

    useEffect(() => {
        (async () => {
            const { data: schedule } = await get_battery_charge_schedule(batteryId);
            setChargeSchedule(schedule);

            const start = new Date(schedule[0].start_time);
            const end = new Date(schedule[0].end_time);
            setPeriod((end.getTime() - start.getTime()) / (60 * 1000));
        })();
    }, [batteryId]);

    return (
        <div className="battery-details__vizualization-container">
            <div className="battery-details__vizualization-container--title">
                <h3>Current Charge Schedule</h3>
                {canManageBatteries && (
                    <Button
                        onClick={event => {
                            event.preventDefault();
                            setConfirmClearModalOpen(true);
                        }}
                        text="Clear Charge Schedule"
                    />
                )}
            </div>
            <div>
                <p>Forecast horizon: {chargeSchedule.length} periods</p>
                <p>Period length: {period} minutes</p>
            </div>

            <div className="battery-details__legend-container">
                <div className="battery-details__legend-container--legend-item">
                    <div style={{ width: '14px', height: '14px', background: 'orange' }} />
                    <p>Scheduled positive power (MW)</p>
                </div>
                <div className="battery-details__legend-container--legend-item">
                    <div style={{ width: '14px', height: '14px', background: 'red' }} />
                    <p>Scheduled negative power (MW)</p>
                </div>
            </div>

            <div className="battery-details__forecast-list-container">
                <div className="battery-details__forecast-list-container--scale">
                    <div>1 MW -</div>
                    <div>-</div>
                    <div>0 MW -</div>
                    <div>-</div>
                    <div>-1 MW -</div>
                </div>
                <ol className="battery-details__delivered-list">
                    {chargeSchedule.map((slot, idx) => {
                        if (idx < chargeSchedule.length - 1) {
                            const new_date =
                                new Date(chargeSchedule[idx].start_time).getDate() !== new Date(chargeSchedule[idx + 1].start_time).getDate();
                            if (new_date) {
                                return (
                                    <Fragment key={`schedule-slot-${idx}`}>
                                        <li>
                                            <ChargeScheduleSlotDetails slot={slot} />
                                        </li>
                                        <li>
                                            <NewDateIndicator height={250} start_time={slot.start_time} />
                                        </li>
                                    </Fragment>
                                );
                            }
                        }
                        return (
                            <li key={`schedule-slot-${idx}`}>
                                <ChargeScheduleSlotDetails slot={slot} />
                            </li>
                        );
                    })}
                </ol>
            </div>
            <ConfirmClearSchedulePopup open={confirmClearModalOpen} setOpen={setConfirmClearModalOpen} batteryId={batteryId} />
        </div>
    );
};

const NewDateIndicator = ({ start_time, height = 250 }) => {
    const start = DateTime.fromISO(start_time);

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: '5 px', left: '5px' }}>
                <p>
                    {start.day + 1}/{start.month}
                </p>
            </div>
            <div style={{ width: '3px', background: 'green' }}>
                <div style={{ height: `${height}px` }}></div>
            </div>
        </div>
    );
};

const ChargeScheduleSlotDetails = ({ slot }) => {
    const { start_time, power } = slot;
    const start = new Date(start_time);

    let height_up = 0;
    let height_down = 0;
    if (power > 0) {
        height_up = 100 * Math.min(1.0, power / 1.0);
    } else if (power < 0) {
        height_down = 100 * Math.min(1.0, -power / 1.0);
    } else {
        height_up = 1;
        height_down = 1;
    }

    return (
        <ChargeScheduleSlotTooltip slot={slot}>
            <div className="battery-details__delivered-item">
                <div style={{ height: `${100 - height_up}px` }}></div>
                <div style={{ height: `${height_up}px`, background: 'orange' }}></div>
                <div style={{ height: `${height_down}px`, background: 'red' }}></div>
                <div style={{ height: `${100 - height_down}px` }}></div>

                <div className="battery-details__delivered-item-bottom-text">
                    <p>
                        {start.getHours().toString().padStart(2, '0')}:{start.getMinutes().toString().padStart(2, '0')}
                    </p>
                </div>
            </div>
        </ChargeScheduleSlotTooltip>
    );
};

const ChargeScheduleSlotTooltip = ({ slot, children }) => {
    const { start_time, end_time, power } = slot;
    const start = new Date(start_time);
    const end = new Date(end_time);

    return (
        <Popup
            trigger={children}
            position="top center"
            contentStyle={{ width: '180px' }}
            closeOnDocumentClick
            on={['hover', 'focus']}
            className="battery-details__delivered-slot-tooltip"
            arrow={true}
            id={`tooltip-${start_time}`}
        >
            <div>
                <div>
                    <p>Date: {start.toLocaleDateString()}</p>
                    <p>Start time: {start.toLocaleTimeString()}</p>
                    <p>End time: {end.toLocaleTimeString()}</p>
                    <p>Power: {parseFloat(power.toString()).toFixed(2)} MW</p>
                </div>
            </div>
        </Popup>
    );
};

const ConfirmClearSchedulePopup = ({ open, setOpen, batteryId }) => {
    const onClose = () => {
        setOpen(false);
    };
    const clearSchedule = async () => {
        await clear_battery_charge_schedule(batteryId);
        onClose();
    };
    if (!batteryId) {
        return <></>;
    }

    return (
        <Popup open={open} closeOnDocumentClick onClose={onClose} className="modal">
            <div>
                <div className="modal__content">Are you sure you want to clear the entire charge schedule?</div>
                <div className="modal__actions">
                    <Button text="Clear Schedule" onClick={clearSchedule} />
                    <Button text="Cancel" onClick={onClose} className="modal__cancel" />
                </div>
            </div>
        </Popup>
    );
};

export default ChargeScheduleVisualizer;
