import React from 'react';
import cc from 'classcat';

export const Button = ({ className, onClick, text, tooltipText, iconSrc, iconPosition, isSubmit, disabled, isSecondary }) => {
    return (
        <>
            <button
                className={cc(['button', className, iconPosition && `button__${iconPosition}`, { 'button--secondary': isSecondary }])}
                type={isSubmit ? 'submit' : 'button'}
                onClick={onClick}
                disabled={disabled}
            >
                {text}
                {iconSrc && <img src={iconSrc} alt="" />}
                {tooltipText && <span className="button__tooltiptext">{tooltipText}</span>}
            </button>
        </>
    );
};
