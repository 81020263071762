import { useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
import App from '../App';
import api from '../api';

// Prefix for API token coming from the internal portal; it is transmitted via the fragment (which means
// it stays local and is not sent to the server, unlike the query string, which keeps the token out of
// access logs).
const API_TOKEN_PREFIX = '#api-token-';

const Secured = () => {
    const [traderName, setTraderName] = useState();
    const [authenticated, setAuthenticated] = useState();
    const [keycloak, setKeycloak] = useState();
    const [tokenExpired, setTokenExpired] = useState();
    const [availableRoles, setAvailableRoles] = useState();

    useEffect(() => {
        if (process.env.REACT_APP_ACCESS_TOKEN) {
            // Token injected at development time by the development runner, provided by Keycloak
            window.auth_token = process.env.REACT_APP_ACCESS_TOKEN;
            api.get_name_and_roles()
                .then(({ name, roles: available_roles }) => {
                    setTraderName(`${name} (Development)`);
                    setAvailableRoles(available_roles);
                    setKeycloak(true);
                    setAuthenticated(true);
                    setTokenExpired(false);
                })
                .catch(error => console.error(`error getting name and roles: ${error.message}`));
        } else if (window.location.hash?.startsWith(API_TOKEN_PREFIX)) {
            // Generated API token from the internal portal, for Emulate staff use. Strip it out of the
            // URL bar.
            window.auth_token = window.location.hash.substring(API_TOKEN_PREFIX.length);
            window.history.pushState('', document.title, window.location.pathname + window.location.search);
            api.get_name_and_roles()
                .then(({ name, roles: available_roles }) => {
                    setTraderName(`${name} (Staff)`);
                    setAvailableRoles(available_roles);
                    setKeycloak(true);
                    setAuthenticated(true);
                    setTokenExpired(false);
                })
                .catch(error => console.error(`error getting name and roles: ${error.message}`));
        } else {
            // Standard login flow via Keycloak.
            const keycloak = Keycloak(process.env.REACT_APP_AUTH_CONFIG);
            keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
                if (authenticated) {
                    window.auth_token = keycloak.token;
                    window.token_parsed = keycloak.tokenParsed;
                    api.get_name_and_roles()
                        .then(({ name: trader_name, roles: available_roles }) => {
                            setTraderName(trader_name);
                            setAvailableRoles(available_roles);
                            setKeycloak(keycloak);
                            setAuthenticated(authenticated);
                            setTokenExpired(false);
                        })
                        .catch(error => console.error(`error getting name and roles: ${error.message}`));
                } else {
                    this.setState({ keycloak, authenticated, trader_name: 'Unknown', tokenExpired: false });
                }
            });

            keycloak.onTokenExpired = () => {
                setTokenExpired(true);
            };
        }
    }, []);

    const handleLogout = () => {
        keycloak.logout({ redirectUri: process.env.REACT_APP_REDIRECT_URI });
    };

    if (keycloak) {
        if (authenticated) {
            if (tokenExpired) {
                return (
                    <div className="session-expired">
                        <img src="/assets/emulate-logo-dark.png" className="session-expired__logo" alt="logo" />
                        <div className="session-expired__box">
                            <p>Session expired. You need to log in again!</p>
                            <button className="session-expired__box--logout-button" onClick={handleLogout}>
                                Log Out
                            </button>
                        </div>
                    </div>
                );
            }
            return <App traderName={traderName} availableRoles={availableRoles} logout={handleLogout} />;
        } else {
            return (
                <div>
                    <p>Unable to authenticate!</p>
                </div>
            );
        }
    }

    return (
        <div>
            <p>Initializing...</p>
        </div>
    );
};

export default Secured;
