import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom';
import Popup from 'reactjs-popup';
import { ToastContainer, toast } from 'react-toastify';

import Loader from '../../components/Loader';
import { Button } from '../../components/Common';
import CreateVirtualBatteryModal from './CreateVirtualBatteryModal';
import { get_virtual_batteries, delete_virtual_battery, get_name_and_roles } from '../../api';

export const BatteryTypes = {
    real_devices: 'Real Devices',
    simulated_devices: 'Simulated Devices',
    simulated_aggregate: 'Simulated Aggregate',
};

const Batteries = () => {
    const [loading, setLoading] = useState(true);
    const [batteries, setBatteries] = useState([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [batteryToDelete, setBatteryToDelete] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [canManageBatteries, setCanManageBatteries] = useState(false);
    const [sortOrder, setSortOrder] = useState(['name', 'asc']);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const [{ data: batteries }, { roles }] = await Promise.all([get_virtual_batteries(), get_name_and_roles()]);
            setBatteries(batteries);
            setCanManageBatteries(roles.brp.has('battery_manage'));
            setLoading(false);
        })();
    }, []);

    const orderBatteries = (column, order) => {
        batteries.sort((a, b) => a[column].localeCompare(b[column]));
        if (order === 'desc') {
            batteries.reverse();
        }
    };

    const handleSort = columnName => {
        const [column, order] = sortOrder;
        orderBatteries(columnName, order);
        if (column === columnName && order === 'asc') {
            setSortOrder([columnName, 'desc']);
        } else {
            setSortOrder([columnName, 'asc']);
        }
    };

    function removeBatteryFromList(batteryId) {
        const remainingBatteries = batteries.filter(bat => bat.id !== batteryId);
        setBatteries(remainingBatteries);
    }
    function addBatteryToList(newBattery) {
        const newBatteries = [newBattery, ...batteries];
        let [column, order] = sortOrder;
        newBatteries.sort((a, b) => a[column].localeCompare(b[column]));
        if (order === 'asc') {
            newBatteries.reverse();
        }
        setBatteries(newBatteries);
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="card-wrapper batteries__wrapper">
            <ToastContainer />
            <h1 className="batteries__title">Virtual Batteries</h1>

            <ConfirmDeleteBatteryPopup
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                battery={batteryToDelete}
                removeBatteryFromList={removeBatteryFromList}
            />

            <CreateVirtualBatteryModal
                open={createModalOpen}
                setOpen={setCreateModalOpen}
                addBatteryToList={addBatteryToList}
                key={'create-battery-modal'}
            />

            <div className="batteries__table-wrapper">
                {batteries.length === 0 ? (
                    <div className="batteries__no-batteries">No batteries found!</div>
                ) : (
                    <table className="batteries__table">
                        <thead className="batteries__table--header">
                            <tr>
                                <th
                                    onClick={() => {
                                        handleSort('name');
                                    }}
                                >
                                    <span className="batteries__table-header-sort">
                                        <i className="fa-solid fa-sort"></i>Name
                                    </span>
                                </th>
                                <th
                                    onClick={() => {
                                        handleSort('market');
                                    }}
                                >
                                    <span className="batteries__table-header-sort">
                                        <i className="fa-solid fa-sort"></i>Market
                                    </span>
                                </th>
                                <th
                                    onClick={() => {
                                        handleSort('price_area');
                                    }}
                                >
                                    <span className="batteries__table-header-sort">
                                        <i className="fa-solid fa-sort"></i>Price Area
                                    </span>
                                </th>
                                <th
                                    onClick={() => {
                                        handleSort('system');
                                    }}
                                >
                                    <span className="batteries__table-header-sort">
                                        <i className="fa-solid fa-sort"></i>Trading System
                                    </span>
                                </th>
                                <th
                                    onClick={() => {
                                        handleSort('type');
                                    }}
                                >
                                    <span className="batteries__table-header-sort">
                                        <i className="fa-solid fa-sort"></i>Type
                                    </span>
                                </th>
                                {canManageBatteries && (
                                    <th>
                                        <span>Delete</span>
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {batteries.map(battery => (
                                <tr
                                    className="batteries__table--row"
                                    key={battery.id}
                                    onClick={() => {
                                        history.push(`/batteries/${battery.id}`);
                                    }}
                                >
                                    <td>
                                        <NavLink exact to={`/batteries/${battery.id}`} className="batteries__name">
                                            {battery.name || 'Unnamed battery'}
                                        </NavLink>
                                    </td>
                                    <td>
                                        <p>{battery.market}</p>
                                    </td>
                                    <td>
                                        <p>{battery.price_area}</p>
                                    </td>
                                    <td>
                                        <p>{battery.system}</p>
                                    </td>
                                    <td>
                                        <p>{BatteryTypes[battery.type]}</p>
                                    </td>
                                    {canManageBatteries && (
                                        <td align="center">
                                            <Button
                                                className="batteries__remove"
                                                onClick={ev => {
                                                    ev.stopPropagation();
                                                    setBatteryToDelete(battery);
                                                    setDeleteModalOpen(true);
                                                }}
                                                text={
                                                    <img src={`/assets/icons/delete.svg`} height={20} className="icon" alt="Delete" title="Delete" />
                                                }
                                            />
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                <div className="batteries__table-wrapper--create-battery">
                    {canManageBatteries && (
                        <Button
                            className={'mt-4'}
                            onClick={event => {
                                event.preventDefault();
                                setCreateModalOpen(true);
                            }}
                            text="Create virtual battery"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const ConfirmDeleteBatteryPopup = ({ open, setOpen, battery, removeBatteryFromList }) => {
    const onClose = () => {
        setOpen(false);
    };

    const removeBattery = async () => {
        try {
            await delete_virtual_battery(battery.id);
            removeBatteryFromList(battery.id);
            toast.success('Removed the virtual battery.');
        } catch (error) {
            toast.error(`Failed to remove virtual battery: ${error}`);
        }
        onClose();
    };
    if (!battery) {
        return <></>;
    }

    return (
        <Popup open={open} closeOnDocumentClick onClose={onClose}>
            <div>
                <button className="modal__close" onClick={onClose}>
                    &#10005;
                </button>
                <div className="modal__header">Remove battery</div>
                <div className="modal__content">Are you sure you want to remove {battery?.name}?</div>
                <div className="modal__actions">
                    <Button text="Delete" onClick={removeBattery} />
                    <Button className="modal__cancel" text="Cancel" onClick={onClose} />
                </div>
            </div>
        </Popup>
    );
};

export default Batteries;
