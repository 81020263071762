import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Chart from 'react-google-charts';
import { granularityToUnitAndFormat } from '../../RetailerReporting';

export const EVAndChargerDistributionGraph = ({ granularity, results, dates }) => {
    const customTooltip = customers => {
        return ReactDOMServer.renderToString(
            <div
                style={{
                    minWidth: '360px',
                    maxWidth: '380px',
                    padding: '5px 10px 5px 10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                    maxHeight: '200px',
                    overflow: 'auto',
                }}
            >
                <strong>Customers:</strong>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {customers.map(customer => {
                        return (
                            <span style={{ float: 'right' }} key={customer}>
                                {customer}
                            </span>
                        );
                    })}
                </div>
            </div>
        );
    };

    // Build up data about EV and charger connectivity.
    const [unit, format] = granularityToUnitAndFormat(granularity);
    const data = [
        [
            unit,
            'EV Only',
            { type: 'string', role: 'tooltip', p: { html: true } },
            'Charger Only',
            { type: 'string', role: 'tooltip', p: { html: true } },
            'EV and Charger',
            { type: 'string', role: 'tooltip', p: { html: true } },
        ],
    ];

    let hasNonZeroData = false;

    results.DISTRIBUTION.forEach(result => {
        const distribution = { evOnly: 0, chargerOnly: 0, both: 0 };
        const customers = { evOnly: [], chargerOnly: [], both: [] };

        result.types.forEach(typePermutation => {
            const hasEv = typePermutation.types.includes('ev');
            const hasCharger = typePermutation.types.includes('cs');

            if (hasEv && hasCharger) {
                distribution.both += typePermutation.count;
                customers.both.push(typePermutation.customer || 'Unknown');
            } else if (hasEv) {
                distribution.evOnly += typePermutation.count;
                customers.evOnly.push(typePermutation.customer || 'Unknown');
            } else if (hasCharger) {
                distribution.chargerOnly += typePermutation.count;
                customers.chargerOnly.push(typePermutation.customer || 'Unknown');
            }
        });

        if (distribution.both || distribution.evOnly || distribution.chargerOnly) {
            hasNonZeroData = true;
        }

        data.push([
            new Date(result.start),
            distribution.evOnly,
            customTooltip(customers.evOnly),
            distribution.chargerOnly,
            customTooltip(customers.chargerOnly),
            distribution.both,
            customTooltip(customers.both),
        ]);
    });

    // Display stacked bar chart.
    const options = {
        isStacked: true,
        hAxis: {
            format,
            slantedText: false,
            textStyle: {
                fontSize: 14,
            },
            ...(dates.length > 0 && { ticks: dates }),
        },
        vAxis: {
            format: '0',
            ...(hasNonZeroData ? {} : { viewWindow: { min: 0, max: 1 } }),
        },
        chartArea: {
            height: '80%',
        },
        tooltip: {
            isHtml: true,
            trigger: 'selection',
        },
    };
    return (
        <div className="reporting__graph-wrapper--chart">
            <h3 className="reporting__title">Customer EV and Charger Distribution</h3>
            <Chart chartType="ColumnChart" width="100%" height="400px" data={data} options={options} />
        </div>
    );
};
