import React from 'react';
import cc from 'classcat';
import { DOTS, usePagination } from './usePagination';

const Pagination = ({ elementsPerPage, totalElements, currentPage, setCurrentPage }) => {
    const setPage = number => {
        setCurrentPage(number);
    };

    const paginate = pageNumber => {
        setPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(totalElements / elementsPerPage)) {
            setPage(currentPage + 1);
        }
    };

    const pages = usePagination({
        currentPage,
        total: totalElements,
        elementsPerPage,
    });

    return (
        <div className="pagination">
            <ul className="pagination__list">
                <li onClick={previousPage} className="pagination__page-number pagination__page-number--prev">
                    &#60;
                </li>
                {pages?.map((number, i) => {
                    if (number === DOTS) {
                        return (
                            <li key={i} className="pagination__page-number">
                                &#8230;
                            </li>
                        );
                    }

                    return (
                        <li
                            key={i}
                            onClick={() => {
                                paginate(number);
                            }}
                            className={cc(['pagination__page-number', { 'pagination__active-page': currentPage === number }])}
                        >
                            {number}
                        </li>
                    );
                })}
                <li onClick={nextPage} className="pagination__page-number pagination__page-number--prev">
                    &#62;
                </li>
            </ul>
        </div>
    );
};

export default Pagination;
