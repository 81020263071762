import { useEffect, useState } from 'react';
import api from '../api';

const useGetAllCustomers = () => {
    const [isReady, setReady] = useState(false);
    const [data, setData] = useState([]);

    const getAllCustomers = async () => {
        try {
            const customers = await api.customer_numbers(true);
            setData(customers?.data);
            setReady(true);
        } catch (error) {
            console.error('Error happened while loading customers. ', error);
        }
    };

    useEffect(() => {
        getAllCustomers();
    }, []);

    return { isReady, data };
};

export default useGetAllCustomers;
