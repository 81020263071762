import 'core-js/full'; // for set operations polyfill

import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import cc from 'classcat';

import { retailerReportingQuery, get_manufacturers } from '../../api';
import { useDebounce } from '../../hooks/useDebounce';

import Pagination from '../../components/Pagination';
import DevicesPageModal from '../../components/Modal/DevicesPageModal';
import { Input, Button, Select } from '../../components/Common';
import Loader from '../../components/Loader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { deviceTypeMap } from '../../utils/constants';
import ManufacturerItem from '../../components/ManufacturerItem';

const AllDevices = () => {
    const [devices, setDevices] = useState({ devices: [], total: 0 });
    const [allDevices, setAllDevices] = useState([]);
    const [totalDeviceCount, setTotalDeviceCount] = useState();
    const [manufacturers, setManufacturers] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState();
    const [showLoader, setShowLoader] = useState(true);

    const INITIAL_PRICE_AREA = { value: '', label: 'Price Area', hidden: true };
    const [priceAreas, setPriceAreas] = useState([INITIAL_PRICE_AREA]);

    const history = useHistory();

    const pagingAndSortingDefaults = {
        sortBy: 'SAVINGS',
        sortDir: 'DESC',
        offset: 0,
        limit: 50,
        currentPage: 1,
    };
    const [{ sortBy, sortDir, offset, limit, currentPage }, setPagingAndSorting] = useState(pagingAndSortingDefaults);

    const filtersDefaults = {
        customer: undefined,
        device_id: undefined,
        manufacturer: undefined,
        connection: undefined,
        spo_enabled: undefined,
        plugged: undefined,
        price_area: undefined,
    };
    const [filters, setFilters] = useState(filtersDefaults);
    // some filters need to be debounced, so we will use indirect objects to keep it feeling snappy
    const rawFiltersDefaults = {
        customer: '',
        device_id: '',
    };
    const [rawFilters, setRawFilters] = useState(rawFiltersDefaults);

    const HEADER = [
        { sort: 'CUSTOMER', label: 'Customer ID' },
        { sort: 'ADDRESS', label: 'Address' },
        { sort: 'PRICE_AREA', label: 'Price Area' },
        { sort: 'MANUFACTURER', label: 'Manufacturer' },
        { sort: 'CONNECTION', label: 'Connection' },
        { sort: 'SPO_ENABLED', label: 'Smart Control' },
        { sort: 'SAVINGS', label: '30-day Savings' },
        { sort: 'PLUGGED', label: 'Plugged In?' },
        { sort: 'POWER', label: 'Power Draw' },
        { sort: 'DATE_ADDED', label: 'Date Added' },
    ];

    const CONNECTION_FILTER_VALUES = [
        { value: 'CONNECTED', label: 'Connected' },
        { value: 'BACKOFF', label: 'Back Off' },
        { value: 'LOST', label: 'Lost' },
        { value: 'CONNECTING', label: 'Connecting' },
    ];

    const DEVICES_PER_PAGE_VALUES = [
        { value: 25, label: '25 Devices Per Page' },
        { value: 50, label: '50 Devices Per Page' },
        { value: 100, label: '100 Devices Per Page' },
    ];

    const PLUGGED_IN_FILTER_VALUES = [
        { value: '', label: 'Plugged In?', hidden: true },
        { value: true, label: 'Plugged' },
        { value: false, label: 'Unplugged' },
    ];

    const SMART_CONTROL = [
        { value: '', label: 'Smart Control', hidden: true },
        { value: true, label: 'Enabled' },
        { value: false, label: 'Disabled' },
    ];

    useEffect(() => {
        getEnabledManufacturers();
        getPriceAreas();
        resetTable();
    }, []);

    useEffect(() => {
        let didCancel = false;
        const fetchDevices = async () => {
            const { devices, total } = await getDevices(sortBy, sortDir, offset, limit, filters);
            if (!didCancel) {
                setDevices({ devices, total });
                setTotalDeviceCount(total);
                setShowLoader(false);
            }
        };

        fetchDevices();
        return () => {
            didCancel = true;
        };
    }, [sortBy, sortDir, offset, limit, filters]);

    const getDevices = async (sortBy, sortDir, offset, limit, filters) => {
        const {
            devices: { devices, total },
        } = await retailerReportingQuery(
            `query devices($sortBy: DeviceListSortBy, $sortDir: SortDirection, $offset: Int, $limit: Int, $filters: DeviceListFilters){
                devices(sortBy: $sortBy, sortDirection: $sortDir, offset: $offset, limit: $limit, filters: $filters) {
                    total,
                    devices {
                        device_id,
                        manufacturer,
                        model,
                        type,
                        customer,
                        date_added,
                        connection,
                        spo_enabled,
                        measurements {
                            power,
                            plugged
                        },
                        period_savings {
                            savings_percentage,
                            explanation
                        },
                        address
                        price_area
                    }
                }
            }`,
            { sortBy, sortDir, offset, limit, filters }
        );

        const flatDevices = devices.map(d => ({
            device_id: d.device_id,
            customer: d.customer,
            address: d.address,
            price_area: d.price_area,
            manufacturer: d.manufacturer,
            connection: d.connection,
            spo_enabled: d.spo_enabled,
            savings: formatSavings(d),
            plugged: d.measurements.plugged,
            power: d.measurements.power,
            date_added: d.date_added,
        }));

        return { devices: flatDevices, total };
    };

    const getPriceAreas = async () => {
        const activePriceAreas = new Set();
        const {
            devices: { devices },
        } = await retailerReportingQuery(`{ devices { devices { price_area } } }`);

        devices.forEach(({ price_area }) => activePriceAreas.add(price_area));
        const priceAreaArray = Array.from(activePriceAreas).map(priceArea => ({
            value: priceArea,
            label: priceArea,
        }));

        setPriceAreas([INITIAL_PRICE_AREA, ...priceAreaArray]);
    };

    const getEnabledManufacturers = async () => {
        const activeManufacturers = new Set();
        const {
            devices: { devices },
        } = await retailerReportingQuery(`{ devices { devices { manufacturer } } }`);
        devices.map(({ manufacturer }) => activeManufacturers.add(manufacturer));

        const allManufacturers = (await get_manufacturers()).data;
        const groupedManufacturers = {};

        allManufacturers.forEach(manufacturer => {
            const { type: abbreviatedType, enabled } = manufacturer;
            // Apply a non-abbreviated label, but if for some reason a new device type exists,
            // fall back to the short code, rather than exploding.
            const type = deviceTypeMap[abbreviatedType.toLowerCase()] ?? abbreviatedType;

            // If the manufacturer is disabled for this retailer, and there are no active devices
            // for this manufacturer type, then don't include it. Note - this is possible because
            // there are currently no guards preventing us from disabling manufacturers when
            // there are active devices for that manufacturer present.
            if (!enabled && !activeManufacturers.has(manufacturer.id)) {
                return;
            }

            const label = enabled ? type : `${type} (onboarding disabled)`;
            if (label in groupedManufacturers) {
                groupedManufacturers[label].push(manufacturer);
            } else {
                groupedManufacturers[label] = [manufacturer];
            }
        });

        // The sort here is because we want to ensure that disabled devices are sorted _after_ enabled.
        // Also, convert to an array of array to make things easier when rendering.
        setManufacturers(Object.entries(groupedManufacturers).sort(([a, _], [b, __]) => a > b));
    };

    const csvRef = useRef();
    const downloadAllDevices = async () => {
        const { devices } = await getDevices(sortBy, sortDir, 0, totalDeviceCount, filters);
        setAllDevices(devices);
        csvRef.current.click();
    };

    const formatSavings = device => {
        if (device.period_savings.explanation) {
            return device.period_savings.explanation;
        }

        if (typeof device.period_savings.savings_percentage === 'number') {
            return Math.ceil(device.period_savings.savings_percentage * 100) + '%';
        }

        return 'Unknown';
    };

    const handleSort = column => {
        if (column === sortBy) {
            setPagingAndSorting(prev => ({ ...prev, sortDir: sortDir === 'ASC' ? 'DESC' : 'ASC' }));
        } else {
            setPagingAndSorting(prev => ({ ...prev, sortBy: column, sortDir: 'ASC' }));
        }
    };

    const resetTable = () => {
        setPagingAndSorting({ ...pagingAndSortingDefaults });
        setFilters({ ...filtersDefaults });
        setRawFilters({ ...rawFiltersDefaults });
    };

    const sortableHeaderColumns = () =>
        HEADER.map(h => (
            <th key={h.sort} onClick={() => handleSort(h.sort)}>
                <span className="devices-table__data--header-cell">
                    <i className="fa-solid fa-sort"></i>
                    {h.label}
                </span>
            </th>
        ));

    const deviceRows = devices =>
        devices.devices.map(d => (
            <tr
                key={d.device_id}
                className="devices-table__data--row"
                onClick={() => {
                    setOpen(true);
                    setSelectedDevice(d);
                }}
            >
                <td>
                    <p>{d.device_id}</p>
                </td>
                <td>
                    <p>{d.customer}</p>
                </td>
                <td>
                    <p>{d.address}</p>
                </td>
                <td>
                    <p>{d.price_area}</p>
                </td>
                <td>
                    <div className="devices-table__data--manufacturer">
                        <ManufacturerItem manufacturer={d.manufacturer} onlyImage={true} />
                    </div>
                </td>
                <td>
                    <p className={cc(['devices-table__data--state', `devices-table__data--state--${d.connection.toLowerCase()}`])}>
                        {d.connection.toLowerCase()}
                    </p>
                </td>
                <td>
                    <p>{d.spo_enabled ? <i className="fa-solid fa-check"></i> : <i className="fa-solid fa-x"></i>}</p>
                </td>
                <td>
                    <p>{d.savings}</p>
                </td>
                <td>
                    <p>{d.plugged ? <i className="fa-solid fa-check"></i> : d.plugged === false ? <i className="fa-solid fa-x"></i> : 'Unknown'}</p>
                </td>
                <td>
                    <p>{d.power}</p>
                </td>
                <td>
                    <p>{new Date(d.date_added).toLocaleDateString()}</p>
                </td>
                <td>
                    <button
                        className="devices-table__data--customer-view-link"
                        onClick={() => history.push(`/devicesdetail/${d.customer}`)}
                        title="Customer View"
                    >
                        <i className="fa-solid fa-arrow-up-right-from-square link-icon"></i>
                    </button>
                </td>
            </tr>
        ));

    const debouncedFilters = useDebounce((param, value) => setFilters(prev => ({ ...prev, [param]: value })));
    const handleRawFilters = (param, value) => {
        setRawFilters(prev => ({ ...prev, [param]: value }));
        debouncedFilters(param, value || undefined);
    };

    const setPage = currentPage => {
        const offset = currentPage * limit - limit;
        setPagingAndSorting(prev => ({ ...prev, currentPage, offset }));
    };

    return (
        <div className="devices-table">
            {showLoader ? (
                <Loader />
            ) : (
                <div className="devices-table__wrap">
                    <div className="devices-table__filters">
                        <Input
                            type="text"
                            placeholder="Device ID"
                            value={rawFilters.device_id}
                            onChange={e => handleRawFilters('device_id', e.target.value)}
                        />

                        <Input
                            type="text"
                            placeholder="Customer ID"
                            value={rawFilters.customer}
                            onChange={e => handleRawFilters('customer', e.target.value)}
                        />

                        <select
                            value={filters.manufacturer || ''}
                            className="select"
                            onChange={e => setFilters(prev => ({ ...prev, manufacturer: e.target.value }))}
                        >
                            <optgroup label="" hidden>
                                <option value="" hidden>
                                    Manufacturer
                                </option>
                            </optgroup>
                            {manufacturers.map(([groupLabel, groupManufacturers]) => (
                                <optgroup key={groupLabel} label={groupLabel}>
                                    {groupManufacturers.map(m => (
                                        <option key={m.id} value={m.id}>
                                            {m.label}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </select>

                        {devices.total > 0 && (
                            <Select
                                value={filters.price_area || ''}
                                options={priceAreas}
                                onChange={e => {
                                    setFilters(prev => ({ ...prev, price_area: e.target.value || undefined }));
                                }}
                            />
                        )}

                        <Select
                            value={filters.connection || ''}
                            options={CONNECTION_FILTER_VALUES}
                            onChange={e => {
                                setFilters(prev => ({ ...prev, connection: e.target.value || undefined }));
                            }}
                        />

                        <Select
                            value={filters.spo_enabled || ''}
                            options={SMART_CONTROL}
                            onChange={e => {
                                setFilters(prev => ({ ...prev, spo_enabled: e.target.value ? JSON.parse(e.target.value) : undefined }));
                            }}
                        />

                        <Select
                            value={filters.plugged || ''}
                            onChange={e => {
                                setFilters(prev => ({ ...prev, plugged: e.target.value ? JSON.parse(e.target.value) : undefined }));
                            }}
                            options={PLUGGED_IN_FILTER_VALUES}
                        />

                        <Select
                            value={limit || ''}
                            onChange={e => {
                                setPagingAndSorting(prev => ({ ...prev, limit: JSON.parse(e.target.value) }));
                            }}
                            options={DEVICES_PER_PAGE_VALUES}
                        />

                        <div className="devices-table__filters--actions">
                            <Button onClick={resetTable} text="Clear Filters" className="devices-table__filters--button" />
                            <div>
                                <Button
                                    className="devices-table__filters--button--download"
                                    text={
                                        <>
                                            Download As CSV <i className="fa fa-download"></i>
                                        </>
                                    }
                                    onClick={downloadAllDevices}
                                />
                                <CSVLink data={allDevices} filename="devices.csv">
                                    <span ref={csvRef}></span>
                                </CSVLink>
                            </div>
                        </div>
                    </div>

                    <div className="devices-table__table-wrapper">
                        <table className="devices-table__data">
                            <thead className="devices-table__data--header">
                                <tr>
                                    <th>Device ID</th>
                                    {sortableHeaderColumns()}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {totalDeviceCount === 0 && (
                                    <tr>
                                        <td colSpan="10" className="devices-table__no-devices-message">
                                            No devices!
                                        </td>
                                    </tr>
                                )}
                                {deviceRows(devices)}
                            </tbody>
                        </table>
                    </div>

                    {totalDeviceCount !== 0 && (
                        <Pagination elementsPerPage={limit} totalElements={totalDeviceCount} currentPage={currentPage} setCurrentPage={setPage} />
                    )}

                    <DevicesPageModal device={selectedDevice} setOpen={setOpen} open={open} devices={devices}></DevicesPageModal>
                </div>
            )}
        </div>
    );
};

export default AllDevices;
