import React from 'react';
import { deviceTypeBreakdownTooltip, granularityToUnitAndFormat } from '../../RetailerReporting';
import Chart from 'react-google-charts';

export const CustomerDeviceCountDistributionGraph = ({ granularity, results, dates }) => {
    // The graph's number of series depends on the maximum number of devices; find that.
    let distribution = results.DISTRIBUTION;
    let maxDevices = 1;
    for (let date of distribution) {
        for (let devices of date.devices) {
            maxDevices = Math.max(maxDevices, devices.devices);
        }
    }

    // Produce the graph series based on that.
    let [unit, format] = granularityToUnitAndFormat(granularity);
    let series = [unit];
    for (let i = 1; i <= maxDevices; i++) {
        series.push(i === 1 ? '1 Device' : `${i} Devices`);
        series.push({ role: 'tooltip', type: 'string', p: { html: true } });
    }

    // Now fill it out with data.
    let data = [series];
    let hasNonZeroData = false;
    for (let date of distribution) {
        let bar = [new Date(date.start)];
        for (let i = 1; i <= maxDevices; i++) {
            let count = date.devices.find(d => d.devices === i)?.count || 0;
            if (count > 0) {
                hasNonZeroData = true;
            }
            bar.push(count);
            bar.push(deviceTypeBreakdownTooltip(date.types.filter(t => t.types.length === i)));
        }
        data.push(bar);
    }

    // Display stacked bar chart.
    const options = {
        isStacked: true,
        hAxis: {
            format,
            slantedText: false,
            textStyle: {
                fontSize: 14,
            },
            ...(dates.length > 0 && { ticks: dates }),
        },
        vAxis: {
            format: '0',
            ...(hasNonZeroData ? {} : { viewWindow: { min: 0, max: 1 } }),
        },
        tooltip: {
            isHtml: true,
            trigger: 'selection',
        },
        chartArea: {
            height: '80%',
        },
    };
    return (
        <div className="reporting__graph-wrapper--chart">
            <h3 className="reporting__title">Customer Device Count Distribution</h3>
            <Chart chartType="ColumnChart" width="100%" height="400px" data={data} options={options} />
        </div>
    );
};
