import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom';

import Loader from '../../components/Loader';
import EditVirtualBatteryModal from './EditVirtualBatteryModal';
import Breadcrumbs from '../../components/Breadcrumbs/breadcrumbs';
import { Button } from '../../components/Common';
import { get_fcr_sessions_for_battery, get_virtual_battery, get_name_and_roles } from '../../api';
import { BatteryTypes } from '../Batteries/Batteries';
import ChargeScheduleVisualizer from './ChargeSchedule';
import { DateTime } from 'luxon';

const BatteryDetails = () => {
    const [loading, setLoading] = useState(true);
    const [battery, setBattery] = useState({});
    const [canManageBatteries, setCanManageBatteries] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    let { id } = useParams();

    useEffect(() => {
        (async () => {
            const [{ data: battery }, { roles }] = await Promise.all([get_virtual_battery(id), get_name_and_roles()]);
            setBattery(battery);
            setCanManageBatteries(roles.brp.has('battery_manage'));
            setLoading(false);
        })();
    }, [id]);

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Breadcrumbs
                crumbs={[
                    { name: 'Virtual Batteries', to: '/batteries' },
                    { name: battery?.name || 'Unnamed Battery', to: `/batteries/${id}` },
                ]}
            />
            <div className="battery-details__two-card-row">
                <div className="card-wrapper">
                    <div className="battery-details__edit">
                        <h3>{battery?.name || 'Unnamed Battery'}</h3>

                        {canManageBatteries && (
                            <Button
                                onClick={event => {
                                    event.preventDefault();
                                    setEditModalOpen(true);
                                }}
                                text="Edit battery"
                            />
                        )}
                    </div>
                    <BatteryDetailsTable battery={battery} />
                    <EditVirtualBatteryModal open={editModalOpen} setOpen={setEditModalOpen} battery={battery} setBattery={setBattery} />
                </div>

                <div className="card-wrapper">
                    <ChargeScheduleVisualizer batteryId={battery.id} />
                </div>
            </div>

            <div>
                <div className="card-wrapper">
                    <h3>Control Sessions</h3>
                    <ControlSessionsList batteryId={battery.id} canManageBatteries={canManageBatteries} />
                </div>
            </div>
        </>
    );
};

const BatteryDetailsTable = ({ battery }) => {
    return (
        <table className="battery-details__table">
            <colgroup>
                <col width="50%" />
                <col width="50%" />
            </colgroup>
            <thead>
                <tr>
                    <th>Property</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Price area</td>
                    <td>{battery.price_area}</td>
                </tr>
                <tr>
                    <td>Grid</td>
                    <td>{battery.grid}</td>
                </tr>
                <tr>
                    <td>Market</td>
                    <td>{battery.market}</td>
                </tr>
                <tr>
                    <td>Type</td>
                    <td>{BatteryTypes[battery.type]}</td>
                </tr>
                <tr>
                    <td>Retailer</td>
                    <td>{battery.retailer}</td>
                </tr>
                <tr>
                    <td>Trading system</td>
                    <td>{battery.system}</td>
                </tr>
                <tr>
                    <td>Trading system settings</td>
                    <td>{JSON.stringify(battery.system_settings, null, 2)}</td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>{battery.status}</td>
                </tr>
                <tr>
                    <td>Period</td>
                    <td>{battery.period} min</td>
                </tr>
                <tr>
                    <td>Forecast horizon</td>
                    <td>{battery.forecast_horizon} periods</td>
                </tr>
                <tr>
                    <td>Market time unit</td>
                    <td>{battery.mtu} min</td>
                </tr>
                <tr>
                    <td>Device labels</td>
                    <td>{JSON.stringify(battery.device_labels ?? {})}</td>
                </tr>
                <tr>
                    <td>Id</td>
                    <td>{battery.id}</td>
                </tr>
            </tbody>
        </table>
    );
};

const ControlSessionsList = ({ batteryId }) => {
    const [sessions, setSessions] = useState([]);
    const [sortDirection, setSortDirection] = useState('desc');
    const history = useHistory();

    useEffect(() => {
        (async () => {
            let sessions = await get_fcr_sessions_for_battery(batteryId);
            setSessions(sessions.data);
        })();
    }, []);

    function sortSessions(sortColumn, sortDir) {
        setSortDirection(sortDir);
        sessions.sort((s1, s2) => {
            const d1 = new Date(s1[sortColumn]);
            const d2 = new Date(s2[sortColumn]);
            return d1.getTime() - d2.getTime();
        });
        if (sortDir === 'asc') {
            sessions.reverse();
        }
        setSessions(sessions);
    }

    return (
        <div className="batteries__table-wrapper">
            {sessions.length === 0 ? (
                <div className="batteries__no-batteries">No sessions found!</div>
            ) : (
                <table className="batteries__table">
                    <thead className="batteries__table--header">
                        <tr>
                            <th>
                                <span>Details</span>
                            </th>
                            <th
                                onClick={() => {
                                    let sortDir = sortDirection === 'desc' ? 'asc' : 'desc';
                                    sortSessions('start', sortDir);
                                }}
                            >
                                <span className="batteries__table-header-sort">
                                    <i className="fa-solid fa-sort"></i>
                                    Start time
                                </span>
                            </th>
                            <th
                                onClick={() => {
                                    let sortDir = sortDirection === 'desc' ? 'asc' : 'desc';
                                    sortSessions('end', sortDir);
                                }}
                            >
                                <span className="batteries__table-header-sort">
                                    <i className="fa-solid fa-sort"></i>
                                    End time
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sessions.map(session => (
                            <tr
                                key={session.id}
                                onClick={() => {
                                    history.push(`/batteries/${batteryId}/sessions/${session.id}`);
                                }}
                                className="batteries__table--row"
                            >
                                <td>
                                    <NavLink exact to={`/batteries/${batteryId}/sessions/${session.id}`}>
                                        See details...
                                    </NavLink>
                                </td>
                                <td>
                                    <p>{DateTime.fromISO(session.start).toFormat('dd-MM-yyyy, HH:mm:ss')}</p>
                                </td>
                                <td>
                                    <p>{DateTime.fromISO(session.end).toFormat('dd-MM-yyyy, HH:mm:ss')}</p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default BatteryDetails;
