import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import sidebarList from './sidebar.json';
import '../../../node_modules/font-awesome/css/font-awesome.min.css';

const SideNavbar = props => {
    let url = props.location.pathname;
    const routes = props.availableRoutes.reduce((routes, route) => {
        routes[route.path] = route.allowedRoles;
        return routes;
    }, {});

    const sidebarElements = sidebarList.reduce((elements, element) => {
        if (routes.hasOwnProperty(element.link)) {
            element['allowedRoles'] = routes[element.link];

            elements.push(
                <Fragment key={element.link}>
                    <li className={url === element.link ? 'sidebar__list--item-active' : 'sidebar__list--item'}>
                        <NavLink exact to={element.link} className="sidebar__list--link">
                            <img src={`/assets/icons/${element.iconName}`} className="icon" alt={element.iconAlt} title={element.title} />
                            <span className="sidebar__list--link--title medium">{element.title}</span>
                        </NavLink>
                    </li>
                </Fragment>
            );
        }

        return elements;
    }, []);

    return (
        <div className="sidebar">
            <ul className="sidebar__list">{sidebarElements}</ul>
        </div>
    );
};

export default withRouter(SideNavbar);
